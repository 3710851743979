<template>
  <div class="edit-reply-wrap">
    <div class="edit-title">
      <div class="back_img">
        <img class="back_img"
             src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
             @click="back_list" alt/>
      </div>
      编辑快捷回复
    </div>

    <div class="reply-content">
      <div class="group-tabs">
        <div class="tab-wrap">
          <van-tabs v-model="currentTag" @change="chooseTag(currentTag)">
            <van-tab v-for="(item,index) in groupList" :key="index" :name="index">
              <template slot="title">
                {{item.name}}
                <span class="iconfont icon-beizhu" v-if="showEditIcon" @click="showGroupModal(0,item)"></span>
                <span class="iconfont icon-delete" v-if="showEditIcon" @click="showDeleteModal(1,item.id)"></span>
              </template>
            </van-tab>
          </van-tabs>
        </div>
        <div>
          <span class="iconfont icon-tianjia1" @click="showGroupModal(1)"></span>
          <span class="iconfont icon-liebiao" @click="()=>showEditIcon = !showEditIcon"></span>
        </div>
      </div>
      <div class="reply-container">
        <div v-if="replys.length==0" class="empty-wrap">
          <img src="https://img.jimeimadou.com/common/seller_xcx_image/order/null.png"/>
          <div class="text">暂无回复模板</div>
        </div>
        <div v-else class="reply-item-container">
          <div class="reply-item" v-for="(item,index) in replys" :key="index">
            <div class="reply-item-content">
              <div class="content">
                {{item.content}}
              </div>
              <div class="iconfont icon-beizhu" @click="showEditModal(0, item)">
              </div>
            </div>
            <div class="iconfont icon-shanchu1" @click="showDeleteModal(0,item.id)"></div>
          </div>
        </div>
      </div>
      <div class="btn" @click="showEditModal(1)">
        新增快捷回复
      </div>
    </div>

    <van-dialog v-model="showEditReply" :title="editTitle" show-cancel-button :before-close="onBeforeClose" @confirm="editReplyHandler">
      <van-field class="reason-input" v-model="edit_reply" rows="2" autosize type="textarea"/>
    </van-dialog>

    <van-dialog v-model="showEditGroup" :title="group_title" show-cancel-button :before-close="onBeforeClose" @confirm="editGroupHandler">
      <van-field class="reason-input group" v-model="group_name" rows="2" autosize type="textarea"/>
    </van-dialog>

    <van-dialog v-model="showDelete" title="温馨提示" show-cancel-button @confirm="deleteHandler">
      此操作将永久删除该内容，是否继续？
    </van-dialog>
  </div>
</template>

<script>
  export default {
    name: "EditReply",
    data() {
      return {
        currentTag: 0,
        groupList: [],
        replys: [],
        showEditReply: false,
        editTitle: '',
        edit_reply: '',
        edit_id: 0,
        group_id: 0,
        del_id: 0,
        showDelete: false,
        showEditGroup: false,
        group_title: '',
        group_name: '',
        showEditIcon: false,
      }
    },
    methods: {
      back_list() {
        this.$router.go(-1);
      },
      //选择分类
      chooseTag(index) {
        this.currentTag = index;
        this.replys = this.groupList[index].data
      },
      //请求分组列表
      getReplyGrouplist() {
        let initparams = {
          access_token: localStorage.getItem('access_token'),
        };
        let params = this.$addParams(initparams);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/quickmessage/grouplist",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res) => {
          if (res.data.status == 'y') {
            let result = JSON.parse(res.data.data);
            console.log("快捷回复分组列表", result);
            this.groupList = result;
            //处理用户删除分组返回时的情况
            if (this.currentTag > this.groupList.length - 1) {
              this.currentTag = 0;
            }
            this.replys = result.length == 0 ? [] : result[this.currentTag].data;
          }
        })
      },
      onBeforeClose(action, done) {
        if (action === 'confirm') {
          return done(false);
        } else {
          return done();
        }
      },
      hideModel(){
        this.showEditReply = false;
        this.showDelete = false;
        this.showEditGroup = false;
        this.showEditIcon = false;
        this.edit_id = 0;
        this.group_id = 0;
        this.del_id = 0;
        this.editTitle = "";
        this.edit_reply = "";
        this.group_title = "";
        this.group_name = "";
      },
      showEditModal(type,info){
        if(type){
          this.editTitle = '新增快捷回复';
          this.group_id = this.groupList[this.currentTag].id;
          this.edit_id = 0;
        }else{
          this.editTitle = '编辑快捷回复';
          this.edit_reply = info.content;
          this.edit_id = info.id;
          this.group_id = 0;
        }
        this.showEditReply = true;
      },
      editReplyHandler(){
        if(!this.edit_reply){
          this.$toast("请填写回复内容");
          return ;
        }
        let params = {};
        let url = "";
        if(this.edit_id){
          params = {
            access_token: localStorage.getItem('access_token'),
            msg_id: this.edit_id,
            content: this.edit_reply
          };
          url = "/mobi/quickmessage/modifymsg";
        }else if(this.group_id){
          params = {
            access_token: localStorage.getItem('access_token'),
            group_id: this.group_id,
            content: this.edit_reply
          };
          url = "/mobi/quickmessage/addmsg";
        }
        this.saveHandler(params, url);
      },
      saveHandler(params,url){
        let initparams = this.$addParams(params);
        this.$axios.post(
          process.env.VUE_APP_URL + url,
          this.$qs.stringify(initparams), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res) => {
          if (res.data.status == 'y') {
            this.$toast("更新成功");
            this.hideModel();
            this.getReplyGrouplist();
          }else{
            this.$toast(res.data.error);
            this.hideModel()
          }
        })
      },
      showDeleteModal(type,id){
        if(type){
          this.group_id = id;
          this.del_id = 0;
        }else{
          this.del_id = id;
          this.group_id = 0;
        }
        this.showDelete = true;
      },
      deleteHandler(){
        let params = {
          access_token: localStorage.getItem('access_token'),
        };
        let url = "";
        if(this.group_id){
          params.group_id = this.group_id;
          url = "/mobi/quickmessage/delgroup"
        }else if(this.del_id){
          params.msg_id = this.del_id;
          url = "/mobi/quickmessage/delmsg";
        }
        let initparams = this.$addParams(params);
        this.$axios.post(
          process.env.VUE_APP_URL + url,
          this.$qs.stringify(initparams), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res) => {
          if (res.data.status == 'y') {
            this.$toast("删除成功");
            this.hideModel();
            this.getReplyGrouplist();
          }else{
            this.$toast(res.data.error);
            this.hideModel()
          }
        })
      },
      showGroupModal(type,info){
        if(type){
          this.group_title = '添加分组';
          this.group_id = 0;
        }else{
          this.group_title = '编辑分组';
          this.group_name = info.name;
          this.group_id = info.id;
        }
        this.showEditGroup = true;
      },
      editGroupHandler(){
        if(!this.group_name){
          this.$toast("请填写分组名称");
          return ;
        }
        let params = {
        };
        let url = "";
        if(this.group_id){
          params = {
            access_token: localStorage.getItem('access_token'),
            group_id: this.group_id,
            name: this.group_name
          };
          url = "/mobi/quickmessage/modifygroup";
        }else {
          params = {
            access_token: localStorage.getItem('access_token'),
            name: this.group_name
          };
          url = "/mobi/quickmessage/addgroup";
        }
        this.saveGroupHandler(params, url);
      },
      saveGroupHandler(params,url){
        let initparams = this.$addParams(params);
        this.$axios.post(
          process.env.VUE_APP_URL + url,
          this.$qs.stringify(initparams), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res) => {
          if (res.data.status == 'y') {
            this.$toast("更新成功");
            this.hideModel();
            this.getReplyGrouplist();
          }else{
            this.$toast(res.data.error);
            this.hideModel()
          }
        })
      },
    },
    activated() {
      this.getReplyGrouplist();
    }
  }
</script>

<style lang="scss" scoped>

  .edit-reply-wrap {
    .edit-title {
      width: 100%;
      height: 80px;
      display: flex;
      font-size: 32px;
      align-items: center;
      padding: 20px;
      justify-content: center;
      position: relative;

      .back_img {
        position: absolute;
        left: 20px;
        width: 50px;
        height: 50px;
        background-color: rgba(220, 220, 220, 0.5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 80%;
          width: 80%;
          margin-left: -10px;
          opacity: 0.6;
        }
      }
    }

    .reply-content {
      height: 100vh;
      background-color: #fff;
      padding: 20px;

      .group-tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tab-wrap {
          flex: 1;
          overflow-x: auto;
          ::v-deep .van-tabs__wrap {
            height: 64px;

            .van-tab {
              max-width: 200px;
              min-width: 100px;
              border: 1px solid #cccccc;
              border-radius: 50px;
              flex: none;
              margin-right: 20px;
              padding: 0 10px;

              .van-tab__text {
                font-size: 28px;
                line-height: 40px;
                .iconfont {
                  font-size: 26px;
                  margin-left: 3px;
                }
              }

              &.van-tab--active {
                color: #fff;
                background-color: #FF6685;
                border-color: #FF6685;
              }
            }

            .van-tabs__line {
              display: none;
            }
          }
        }

        .iconfont {
          font-size: 50px;
          margin-left: 20px;
        }
      }

      .reply-container {
        margin-top: 20px;

        .empty-wrap {
          height: 500px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            width: 300px;
            height: auto;
            display: block;
          }

          .text {
            margin-top: 20px;
            color: #aaa;
            font-size: 28px;
          }
        }

        .reply-item-container {
          text-align: left;

          .reply-item {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .reply-item-content {
              background-color: #F6F6F6;
              border-radius: 10px;
              font-size: 26px;
              padding: 15px 20px;
              color: #262A33;
              line-height: 40px;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .iconfont {
                color: #666;
                font-size: 30px;
              }
            }

            .iconfont {
              font-size: 45px;
              padding-left: 20px;
            }
          }
        }
      }

      .btn {
        position: fixed;
        bottom: 90px;
        left: 50%;
        transform: translateX(-200px);
        text-align: center;
        width: 400px;
        height: 80px;
        line-height: 80px;
        background-color: #000;
        color: #fff;
        font-size: 35px;
        border-radius: 40px;
      }
    }
  }

  /* 弹框相关 */

  ::v-deep .van-overlay {
    background-color: rgba(0, 0, 0, .4);
  }

  .van-dialog {
    width: 570px;
    font-size: 30px;
    padding: 30px 40px;

    ::v-deep .van-dialog__header {
      font-size: 34px;
      font-weight: 600;
      padding-top: 15px;
    }

    ::v-deep .van-dialog__content {
      margin-top: 30px;
      text-align: center;
      line-height: 40px;

      .reason-input {
        width: 100%;
        height: 150px;
        border-radius: 10px;
        background-color: #f7F7F7;
        padding: 15px 20px;
        line-height: 40px;
        font-size: 26px;
        text-align: left;
        &.group {
          height:80px;
        }
      }
    }

    ::v-deep .van-dialog__footer {
      margin-top: 30px;
      justify-content: center;

      .van-button {
        font-size: 32px;
        height: 68px;
        border-radius: 50px;
        flex: none;
        width: 220px;

        &.van-dialog__confirm {
          background-color: #000;
          color: #fff;
        }

        &.van-dialog__cancel {
          border: 1px solid #8c8c8c;
          margin-right: 40px;
        }
      }
    }
  }

</style>