<template>
	<div class="image-container" :style="'border-radius: '+borderRadius+'rpx;'">
		<img class="image" :style="load_success?'display: block;':'display:none;'" :src="src" @load="loadSuccess" @error="setErrorSrc">
		<img class="load-image" v-if="!load_success" :src="loading_icon">
	</div>
</template>

<script>
	export default {
		name: "my-image",
		props: {
			src: {
				type: String,
				default: ""
			},
			borderRadius: {
				type: [String, Number],
				default: 0
			},
		},
		data() {
			return {
				load_success: false,
				loading_icon:"https://img.jimeimadou.com/common/seller_xcx_image/index/loading.gif"
			};
		},
		methods: {
			loadSuccess() {
				console.log("加载成功！！")
				this.load_success = true
			},
			setErrorSrc() {
				console.log("加载失败！",this.src)
				this.loading_icon = "https://img.jimeimadou.com/common/icon/image-fail.png"
			}
		}
	}
</script>

<style lang="scss">
	.image-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// background-color: #ddd;
		overflow: hidden;

		.image {
			width: 100%;
			height: auto;
			display: block;
		}
		.load-image{
			width: 80px;
			height: 80px;
			display: block;
			object-fit: content;
		}
	}
</style>
