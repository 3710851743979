<template>
	<div>
		<navbar title="邀请赚佣金"></navbar>
		<div class="invite_container">
			<!-- 邀请奖励 -->
			<div class="user_money_container">
				<div class="user_money_content">
					<div class="user_money_itemcontainer">
						<div class="user_money_itemview">
							<div class="user_money_itemnumview" style="color:#262A33">
								<div class="user_money_itemnum">{{detail.total_mt_num?detail.total_mt_num:0}}</div>
								<div class="user_money_itemnumunit">人</div>
							</div>
							<div class="user_money_itemsubtitle" @click="toDetail('user')">邀请模特 ></div>
						</div>
						<div class="user_money_itemview">
							<div class="user_money_itemnumview" style="color:#262A33">
								<div class="user_money_itemnum">{{detail.total_mt_auth_num?detail.total_mt_auth_num:0}}
								</div>
								<div class="user_money_itemnumunit">人</div>
							</div>
							<div class="user_money_itemsubtitle">认证模特 ></div>
						</div>
						<div class="user_money_itemview">
							<div class="user_money_itemnumview" style="color:#262A33">
								<div class="user_money_itemnum">{{detail.total_seller_num?detail.total_seller_num:0}}
								</div>
								<div class="user_money_itemnumunit">人</div>
							</div>
							<div class="user_money_itemsubtitle" @click="toDetail('seller')">邀请商家 ></div>
						</div>
						<div class="user_money_itemview">
							<div class="user_money_itemnumview" style="color:#262A33">
								<div class="user_money_itemnum">
									{{detail.total_invite_commission?detail.total_invite_commission:"0.00"}}
								</div>
								<div class="user_money_itemnumunit">￥</div>
							</div>
							<div class="user_money_itemsubtitle" @click="toDetail('money')">邀请总佣金 ></div>
						</div>
					</div>
				</div>
			</div>


			<div class="invite_explain_container">
				<div class="invite_explain_content">
					<div class="invite_explain_titleview">邀请模特说明</div>
					<div class="invite_explain_desview">
						<div class="invite_explain_dianview">
							<div class="invite_explain_dian"></div>
						</div>
						<div class="invite_explain_des">被邀请模特每次接单，您都可以获得0.5元奖励。</div>
					</div>
				</div>
			</div>

			<div class="invite_explain_container">
				<div class="invite_explain_content">
					<div class="invite_explain_titleview">邀请商家说明</div>
					<div class="invite_explain_desview">
						<div class="invite_explain_dianview">
							<div class="invite_explain_dian"></div>
						</div>
						<div class="invite_explain_des">被邀请商家每次派单，您都可以获得1元奖励。</div>
					</div>
				</div>
			</div>

			<div class="invite_explain_container">
				<div class="invite_explain_content">
					<div class="invite_explain_titleview">注明</div>
					<div class="invite_explain_desview">
						<div class="invite_explain_dianview">
							<div class="invite_explain_dian"></div>
						</div>
						<div class="invite_explain_des">所有奖励上不封顶，长期有效！</div>
					</div>
					<div class="invite_explain_desview">
						<div class="invite_explain_dianview">
							<div class="invite_explain_dian"></div>
						</div>
						<div class="invite_explain_des">禁止推广自己的作弊行为，发现将取消资格</div>
					</div>
					<div class="invite_explain_desview">
						<div class="invite_explain_dianview">
							<div class="invite_explain_dian"></div>
						</div>
						<div class="invite_explain_des">最终解释权归灰豚通告所有</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="showLoginBtn==0" class="invite_bottom_container">
			<div class="invite_btn" @click="showModel('leftModel')">邀请模特</div>
			<div class="invite_line"></div>
			<div class="invite_btn" @click="showModel('rightModel')">邀请商家</div>
		</div>
		<div v-if="showLoginBtn==1" class="invite_bottom_container" @click="toLogin">
			<div class="nologin">请先登录</div>
		</div>

		<van-popup class="vant-popup" v-model="postModel_show">
			<div class="post-model">
				<img class="post-image" :src="postImage"></img>
				<div class="save-btn-con">
					<div class="save-btn">长按图片可保存</div>
				</div>
			</div>
		</van-popup>
		
		<van-popup v-model="bottomModel_show" round position="bottom">
			<div class="action-con" v-if="modelName=='leftModel'">
				<div class="invite_select_itemcontent" @click="showModel('postModel')">
					<img class="invite_select_itemimg"
						src="https://img.jimeimadou.com/common/resource/invite_icon/invite_hb_icon.png"></img>
					<div class="invite_select_itemright">
						<div class="invite_select_itemtitle">生成海报</div>
						<div class="invite_select_itemsubtitle">生成含有推广码的宣传海报</div>
					</div>
				</div>
				<div class="invite_select_itemcontent" @click="copyUrl(detail.invite_user_url)">
					<img class="invite_select_itemimg"
						src="https://img.jimeimadou.com/common/resource/invite_icon/invite_link_icon.png"></img>
					<div class="invite_select_itemright">
						<div class="invite_select_itemtitle">复制链接</div>
						<div class="invite_select_itemsubtitle">适合微信外的分享场均，如QQ、微博</div>
					</div>
				</div>
			</div>
			<div class="action-con" v-if="modelName=='rightModel'">
				<div class="invite_select_itemcontent" @click="showModel('postModel')">
					<img class="invite_select_itemimg"
						src="https://img.jimeimadou.com/common/resource/invite_icon/invite_hb_icon.png"></img>
					<div class="invite_select_itemright">
						<div class="invite_select_itemtitle">生成海报</div>
						<div class="invite_select_itemsubtitle">生成含有推广码的宣传海报</div>
					</div>
				</div>
				<div class="invite_select_itemcontent" @click="copyUrl(detail.invite_seller_url)">
					<img class="invite_select_itemimg"
						src="https://img.jimeimadou.com/common/resource/invite_icon/invite_link_icon.png"></img>
					<div class="invite_select_itemright">
						<div class="invite_select_itemtitle">复制链接</div>
						<div class="invite_select_itemsubtitle">适合微信外的分享场均，如QQ、微博</div>
					</div>
				</div>
			</div>
			<div class="invite_modeltype_btnview" @click="hideModel">取消</div>
		</van-popup>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	export default {
		data() {
			return {
				show_seller_invite: false,
				show_model_invite: false,
				detail: {
					total_mt_num: 0,
					total_seller_num: 0,
					total_invite_commission: "0.00"
				},
				modelName: "",
				postImage: "",
				showLoginBtn: 0,
				isShow:false,
				postModel_show:false,
				bottomModel_show:false,
				detail:{}
			}
		},
		components:{
			navbar
		},
		activated() {
			this.getInviteInfo()
		},
		methods:{
			toDetail(type){
				this.$router.push({
					name: "inviteDetail",
					query: {
						type
					}
				})
			},
			showModel(name) {
				if(this.detail.invite_conf==0){
					this.$toast("该账号邀请权限已冻结")
					return
				}
				if(name=="leftModel"){
					this.postImage = this.detail.invite_user_img
				}else if(name=="rightModel"){
					this.postImage = this.detail.invite_seller_img
				}else if(name=="postModel"){
					this.postModel_show = true
					this.bottomModel_show = false
					return
				}
				this.modelName = name
				this.bottomModel_show = true
			},
			hideModel() {
				this.bottomModel_show = false
				this.postModel_show = false
			},
			toLogin(){
				this.$router.push({
					name: "login"
				});
			},
			getInviteInfo() {
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					from_xcx:"jmmdplus"
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/invite",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							let result = JSON.parse(res.data.data)
							console.log("分享参数",result)
							this.detail = result
							// this.detail.total_invite_commission = parseFloat(this.detail.total_invite_commission).toFixed(2)
							// getApp().globalData.my_invite_code = result.invite_code
							// getApp().globalData.my_channel = "seller"
							this.showLoginBtn = 0
						}else{
							this.showLoginBtn = 1
						}
					});
			},
			copyUrl(url){
				this.$copyText(url).then(() => {
				    this.$toast('复制成功');
				  }, () => {
				    this.$toast('复制失败');
				  }
				)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.invite_container {
		width: 100%;
		position: relative;
	}


	.user_money_container {
		width: 100%;
		padding: 20px 24px 0;
		box-sizing: border-box;
	}

	.user_money_content {
		width: 100%;
		background-color: white;
		border-radius: 24px;
	}


	.user_money_titleview {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 20px 0 20px;
		box-sizing: border-box;
	}

	.user_money_title {
		height: 45px;
		font-size: 32px;
		font-family: PingFangSC-Semibold, PingFang SC;
		color: #262A33;
	}

	.user_money_subtitleview {
		display: flex;
		align-items: center;
	}

	.user_money_subtitle {
		font-size: 24px;
		color: #8A8E99;
	}

	.user_money_subtitle_icon {
		width: 14px;
		height: 14px;
		margin-left: 6px;
	}

	.user_money_itemcontainer {
		width: 100%;
		height: 185px;
		display: flex;
		box-sizing: border-box;
	}

	.user_money_itemview {
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
	}

	.user_money_itemnumview {
		font-family: DINAlternate-Bold, DINAlternate;
		font-weight: bold;
		color: #FF4E33;
		display: flex;
		align-items: flex-end;
	}

	.user_money_itemnum {
		height: 47px;
		font-size: 40px;
		line-height: 47px;
	}

	.user_money_itemnumunit {
		font-size: 24px;
		line-height: 38px;
	}

	.user_money_itemtitle {
		margin-top: 40px;
		height: 40px;
		font-size: 28px;
		font-family: PingFangSC-Semibold, PingFang SC;
		color: #FF4E33;
		line-height: 40px;
	}

	.user_money_itemsubtitle {
		margin-top: 10px;
		height: 33px;
		font-size: 20px;
		color: #8A8E99;
		line-height: 33px;
	}


	.invite_explain_container {
		width: 100%;
		margin-top: 20px;
		padding: 0 24px;
		box-sizing: border-box;
	}

	.invite_explain_content {
		width: 100%;
		background-color: white;
		border-radius: 24px;
		padding: 30px 20px;
		box-sizing: border-box;
	}

	.invite_explain_titleview {
		width: 100%;
		height: 45px;
		font-size: 32px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #262A33;
		line-height: 45px;
		text-align: center;
	}

	.invite_explain_desview {
		width: 100%;
		display: flex;
		margin-top: 30px;
	}

	.invite_explain_dianview {
		display: flex;
		width: 8px;
		height: 37px;
		align-items: center;
	}

	.invite_explain_dian {
		width: 8px;
		height: 8px;
		background-color: #FF6666;
		border-radius: 50%;
		flex-shrink: 0;
	}

	.invite_explain_des {
		font-size: 26px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #262A33;
		line-height: 37px;
		margin-left: 6px;

	}

	.invite_btn_container {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.invite_type_container {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 324px;
		background-color: white;
		border-radius: 36px 36px 0 0;
		display: flex;
		padding: 0 131px;
		box-sizing: border-box;
		justify-content: space-between;
	}

	.invite_type_item {
		width: 148px;
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.invite_type_itemimg {
		width: 148px;
		height: 148px;
		background-color: red;
		margin-top: 82px;
	}

	.invite_type_itemtitme {
		height: 42px;
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8A8E99;
		line-height: 42px;
		margin-top: 31px;
	}

	.invite_modeltype_container {
		width: 100%;
		position: absolute;
		bottom: 0;
		background-color: white;
		border-radius: 36px 36px 0 0;
	}

	.invite_modeltype_content {
		width: 100%;
		padding: 0 0 60px 0;
		box-sizing: border-box;
	}

	.model-con {
		border-radius: 25px 25px 0 0 !important;
		overflow: hidden;
		padding: 0;
	}

	.invite_select_itemcontent {
		width: 100%;
		display: flex;
		padding: 30px 80px;
		box-sizing: border-box;
	}

	.invite_select_itemimg {
		width: 100px;
		height: 100px;
		flex-shrink: 0;
		border-radius: 50%;
	}

	.invite_select_itemright {
		position: relative;
		height: 100px;
		width: 100%;
		padding: 8px 0 7px 20px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.invite_select_itemtitle {
		height: 42px;
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #262A33;
		line-height: 42px;
	}

	.invite_select_itemsubtitle {
		height: 33px;
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #B8BECC;
		line-height: 33px;
		margin-top: 10px;
	}

	.invite_modeltype_btnview {
		width: 100%;
		height: 100px;
		border-top: 1px solid #EBEEF5;
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #262A33;
		line-height: 100px;
		text-align: center;
	}


	.invite_bottom_container {
		width: 100%;
		position: fixed;
		bottom: 0;
		height: 98px;
		background-color: #FF6685;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.invite_btn {
		width: 100%;
		height: 374px;
		height: 100%;
		text-align: center;
		font-size: 34px;
		color: white;
		line-height: 98px;
	}

	.invite_line {
		width: 2px;
		height: 38px;
		background-color: white;
	}

	.mt_share_button {
		height: 100% !important;
		width: 100% !important;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
	}

	/* 海报 */
	.invite_pic_wrap {
		height: 100vh;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.5);
		z-index: 5000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	.save_haibao {
		width: 400px;
		height: 80px;
		border-radius: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #333;
		background: yellow;
		margin-top: 30px;
	}
	.vant-popup{
		background-color: transparent;
	}

	.post-model {
		width: 550px;
		background-color: transparent;

		.post-image {
			width: 100%;
			border-radius: 10px;
		}
		.save-btn-con{
			display: flex;
			justify-content: center;
			padding-top: 50px;
		}

		.save-btn {
			background-color: yellow;
			width: 360px;
			line-height: 80px;
			height: 80px;
			border-radius: 40px;
			text-align: center;
		}
	}

	.nologin {
		font-size: 32px;
		color: #fff;
	}
	.action-con{
		padding: 20px 0;
	}
</style>
