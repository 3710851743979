<template>
	<div>
		<div class="header-container">
			<div class="header">
				<i class="iconfont icon-fanhui" @click="back_action"></i>
				<img class="head_ico" :src="album_info.head_ico||'https://img.jimeimadou.com/common/images/zhanwei.png'" mode="aspectFill" @click="toModelDetail"></img>
				<div class="model-info" @click="toModelDetail">
					<div class="model-name">{{album_info.nick_name}}</div>
					<div class="model-params">
						<div class="model-age" v-if="album_info.age&&album_info.age!=0">{{album_info.age}}岁·</div>
						<div class="model-city" v-if="album_info.province">{{album_info.province}}·</div>
						<div class="model-order">接单{{album_info.send_order_num||0}}</div>
					</div>
				</div>
			</div>
		</div>
		<video v-if="album_type==2" class="album-video" autoplay :src="album_info.video" controls></video>		
		<van-swipe v-else class="album-swiper" @change="swiperChange" duration="300">
			<van-swipe-item class="swiper-item" v-for="(item,index) in album_info.photo_list" :key="item">
				<!-- <image-loader height="auto" :hasLoadingGif="true" :src="item+compressParams+waterMark" ></image-loader> -->
				<my-image :hasLoadingGif="true" :src="item+compressParams+waterMark"></my-image>
			</van-swipe-item>
			<van-swipe-item class="swiper-item" v-if="album_info.video">
				<!-- <image-loader height="auto" :hasLoadingGif="true" :src="album_info.cover+compressParams+waterMark" ></image-loader> -->
				<my-image :hasLoadingGif="true" :src="album_info.cover+compressParams+waterMark"></my-image>
				<div class="video-mask" @click="toVideoDetail">
					<img class="play-icon" src="../../../public/static/play.png" mode="aspectFit"></img>
				</div>
			</van-swipe-item>
		</van-swipe>
		
		<div class="album-bottom">
			<div class="title-con">
				<div class="pic_num" v-if="album_type!=2">{{current}} / {{album_info.photo_num||0}}</div>
				<div class="album-title" v-if="album_info.title">{{album_info.title}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import myImage from "../../components/my-image.vue"
	export default {
		data() {
			return {
				mt_id: 0,
				album_id: 0,
				album_type: 0,
				album_info: {},
				current: 1,
				compressParams: "?imageView2/2/w/1000", //图片压缩参数
				waterMark: "|watermark/1/blogo/2/image/aHR0cDovL2ptc3RhdGljLTEyNTU0OTc5MTYuY29zLmFwLXNoYW5naGFpLm15cWNsb3VkLmNvbS9jb21tb24vd2F0ZXJtYXJrZXIucG5n/dissolve/90/dx/10/dy/10"
			}
		},
		components:{
			myImage
		},
		activated() {
			console.log(this.$route.query)
			let query = this.$route.query
			this.mt_id = query.mt_id
			this.album_id = query.album_id
			this.album_type = query.album_type
			this.getAlbumDetail()
		},
		deactivated() {
			this.mt_id = 0
			this.album_id = 0
			this.album_type = 0
			this.album_info = {}
			this.current = 1
		},
		methods: {
			initData(){
				
			},
			back_action() {
				this.$router.back(-1);
			},
			getAlbumDetail() {
				let sentData = {
					mt_id:this.mt_id,
					photo_id: this.album_id,
					type: this.album_type
				}
				let params = this.$addParams(sentData)
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/familyphotodetail",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "y") {
							let result = JSON.parse(res.data.data)
							console.log("相册详情", result)
							result.photo_list = result.photo_list ? result.photo_list.split(this.album_type == 3 ?
								"," : ";") : []
							// 非种草相册显示轮播图数量
							if (this.album_type != 2) {
								result.photo_num = result.photo_list.length + (result.video ? 1 : 0)
							}
							this.album_info = result
						} else {
							this.$toast(res.data.error)
						}
					});
			},
			swiperChange(index) {
				this.current = index + 1;
			},
			toModelDetail() {
				this.$router.push({
					name: "modelDetail",
					query:{
						mt_id:this.mt_id,
						fromPage:"other"
					}
				});
			},
			toVideoDetail(){
				this.$router.push({
					name: "videoDetail",
					query:{
						url:this.album_info.video
					}
				});
			},
		}
	}
</script>

<style scoped lang="scss">
	.header-container {
		position: fixed;
		width: 100%;
		top: 0;
		background-color: #000;
		z-index: 100;

		.header {
			height: 120px;
			color: #fff;
			display: flex;
			align-items: center;

			.icon-fanhui {
				font-size: 34px;
				padding: 10px 15px;
				color: #fff;
			}

			.head_ico {
				height: 80px;
				width: 80px;
				border-radius: 50%;
				margin-right: 20px;
				object-fit: cover;
			}

			.model-info {
				line-height: 40px;
				flex: 1;

				.model-name {
					font-size: 30px;
				}

				.model-params {
					font-size: 24px;
					display: flex;
				}
			}
		}
	}


	.album-swiper {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background-color: #000;
	}

	.swiper-item {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
	}

	.album-video {
		position: fixed;
		top: 120px;
		left: 0;
		height: calc(100vh - 300px) ;
		width: 100%;
		bottom: 180px;
		background-color: #000;
	}

	.album-bottom {
		position: fixed;
		bottom: 0;
		width: 100%;
		background-color: #000;
		padding: 20px;
		box-sizing: border-box;
		height: 180px;
		display: flex;
		justify-content: space-between;

		.title-con {
			flex: 1;
			overflow: hidden;
			color: #fff;
			margin-right: 20px;

			.pic_num {
				color: #fff;
				font-size: 32px;
			}
			.album-title{
				line-height: 40px;
				font-size: 30px;
			}
		}

		.btn-con {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.follow-btn {
				height: 70px;
				width: 70px;
				border-radius: 50%;
			}
		}
	}
	.video-mask{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.play-icon{
			width: 60px;
			height: 60px;
			display: block;
			object-fit: content;
		}
	}
</style>
