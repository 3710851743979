<template>
  <div class="chat-wrap">
    <div class="chat-title">
      <div class="back_img">
        <img class="back_img"
             src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
             @click="back_list" alt/>
      </div>
      {{nick || '消息列表'}}
    </div>

    <van-loading v-if="loading" type="spinner" color="#1989fa">加载中</van-loading>

    <scroll-y class="chat-container" ref="scroll" @pullRefresh="getMessageList">
      <div class="message-container">
        <div v-for="(item,index) in messages" :key="index">
          <div class="chat-time-header" v-if="item.displayTimeHeader">
            <div class="time-msg">{{item.displayTimeHeader}}</div>
          </div>

          <div class="chat-time-header" v-if="item.type=='tip'">
            <div class="time-msg">{{item.tipMsg}}</div>
          </div>

          <div v-else class="message-item" @click="hideOperation"
               :style="item.sendOrReceive=='receive' ? 'padding-right: 80px;' : 'flex-direction: row-reverse;padding-left: 80px;'">
            <img class="avatar" :src="item.avatar" @click="toMtDetail(item.sendOrReceive)"/>
            <div class="message-con" v-longpress="() => showOperation(index)">
              <!-- 文字消息 -->
              <div v-if="item.type=='text'" class="message-text"
                   :class="item.sendOrReceive=='receive' ? 'text-other' : 'text-mine'">
                <div v-for="(item2,index2) in item.text" :key="index2">
                  <span class="text" v-if="item2.type=='text'">{{item2.text}}</span>
                  <img v-if="item2.name=='img'" class="emoji-icon" :src="item2.attrs.src"/>
                </div>
              </div>
              <!-- 图片消息 -->
              <span @click="previewImage(item.image.url)">
                <img v-if="item.type=='image'" class="message-image" :src="item.image.url"/>
              </span>
              <!-- :style="'height: '+(300*item.image.h/item.image.w)+'px;'"-->
              <!-- 视频消息 -->
              <video v-if="item.type=='video'" class="message-video" :src="item.video.url" controls @click="toVideoDetail(item.video.url)"></video>
              <!--  <div class="video-position" v-if="item.type=='video'" @click="toVideoDetail(item.video.url)">
                     <video class="message-video" :src="item.video.url" controls></video>
                     <img class="play-icon" src="../../../public/static/play.png"/>
                   </div>-->
              <!-- 自定义消息 -->
              <div v-if="item.type=='custom'">
                <!-- 转账消息 -->
                <div v-if="item.content.value" class="custom-money">
                  <div class="flex">
                    <span class="iconfont icon-zhuanzhang1"></span>
                    <div>
                      <div class="money-value">￥{{item.content.value}}</div>
                      <div class="money-name">转账给你</div>
                    </div>
                  </div>
                  <div class="custom-money-tip">已到账</div>
                </div>

                <!-- 文本引用消息 -->
                <div v-if="item.content.referContent" class="message-text"
                     :class="item.sendOrReceive=='receive'?'text-other':'text-mine'">
                  <div v-for="(item2,index2) in item.content.replyText_arr" :key="index2">
                    <div v-if="item2.type=='text'">{{item2.text}}</div>
                    <img v-if="item2.name=='img'" class="emoji-icon" :src="item2.attrs.src"/>
                  </div>
                </div>

                <!-- 卡片消息 -->
                <div v-if="item.content.task_id" class="message-link"
                     :class="item.sendOrReceive=='receive'?'link-other':'link-mine'"
                     @click="toOrderDetail(item.content.order_id)">
                  <div class="task-no">订单编号：{{item.content.order_sn}}</div>
                  <div class="task-msg">
                    <img class="task-cover" :src="item.content.goods_pic"/>
                    <div class="task-msg-right">
                      <div class="">
                        <div class="task-name">{{item.content.goods_name}}</div>
                        <div class="task-tag-con">
                          <img v-if="item.content.task_require==4" class="xhs"
                               :src="xhsType[item.content.xhs_prom_type]"/>
                          <div class="type-tip type2" v-if="item.content.task_require==1">买家秀</div>
                          <div class="type-tip type2" v-if="item.content.task_require==3">主图/详情页</div>
                          <div class="type-tip type2" v-if="item.content.task_require==2">
                            <div class="zc-icon">
                              <img class="goods_labelimg" src="../../../public/static/zc.png"/>
                              <div>种草</div>
                            </div>
                          </div>
                          <div v-if="item.content.xhs_prom_type!=3">
                            <div class="type-tip type3" v-if="item.content.task_type==1">赠拍</div>
                            <div class="type-tip type4" v-if="item.content.task_type==2">寄拍</div>
                            <div class="type-tip type1" v-if="item.content.task_require_sub==1">口播介绍</div>
                            <div class="type-tip type1" v-if="item.content.task_require_sub==2">开箱测评</div>
                            <div class="type-tip type1" v-if="item.content.task_require_sub==3">时尚穿搭</div>
                          </div>
                        </div>
                      </div>
                      <div class="task-price" v-if="item.content.mt_price">
                        佣金：
                        <span style="color: #FF6685;">￥{{item.content.mt_price}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="read-status" v-if="item.sendOrReceive=='send'">
                {{item.isLastRead?'已读':'未读'}}
              </div>

              <div v-if="item.type!='custom'" class="operation-wrap"
                   :class="longTapIndex==index ? 'operation-show' : ''">
                <div class="operation-con">
                  <div v-if="item.type!='image' && item.type!='video'" class="copy">
                    <div class="operation-item" @click="copyContent(index)">复制</div>
                    <div class="line"></div>
                  </div>
                  <div class="operation-item" @click="referenceContent(index)">引用</div>
                  <div v-if="item.sendOrReceive=='send'" class="repeal">
                    <div class="line"></div>
                    <div class="operation-item" @click="repealContent(index)">撤回</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 引用内容 -->
          <div v-if="item.referContent" class="msg-reference-wrap flex"
               :class="item.sendOrReceive=='send' ? 'justify-end' : 'justify-start'">
            <div class="msg-reference-con flex">
              <div class="msg-reference-nick">{{item.referFromNick}}</div>
              <div>：</div>
              <div class="flex-sub">
                <img v-if="item.referType=='image'" class="reference-img" :src="item.referContent.url"
                     @click="previewImage(item.referContent.url)"/>
                <video v-else-if="item.referType=='video'" class="reference-video" :src="item.referContent.url" controls></video>
                <!--<div v-else-if="item.referType=='video'" class="video-wrap"  @click="toVideoDetail(item.referContent.url)">
                     <video class="reference-video" :src="item.referContent.url"></video>
                     <img class="play-icon" style="width: 32px;" src="../../../public/static/play.png"/>
                    </div>-->
                <div v-else class="msg-reference">
                  <div v-for="(item2,index2) in item.referContent" :key="index2">
                    <div v-if="item2.type=='text'">{{item2.text}}</div>
                    <img v-if="item2.name=='img'" class="emoji-icon" :src="item2.attrs.src"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-container"></div>
    </scroll-y>

    <div class="bottom-container">
      <div class="link-container" v-if="orderInfo.order_id">
        <div class="link-item" @click="sendCustomMsg">
          <span class="iconfont link-icon icon-lianjie"></span>
          <div class="link-name">{{orderInfo.goods_name}}</div>
        </div>
      </div>

      <div class="bottom-input-container">
        <div class="bottom-input">
          <div class="input-container">
            <input type="text" ref="msgInput" v-model="inputValue" @confirm='inputSend' @keyup.enter="inputSend"
                   :cursor-spacing="cursorSpacing"/>
            <span class="iconfont input-icon icon-kuaijie" @click="showModel"></span>
          </div>
          <span class="iconfont media-icon icon-zhaopian">
            <input class="image-input" ref="imagePicker" type="file"
                   @change="sendImageToNOS"
                   accept="image/*"/>
          </span>
          <span class="iconfont media-icon icon-shipin">
            <input class="video-input" ref="imagePicker" type="file"
                   @change="sendVideoToNos"
                   accept="video/*"/>
          </span>
        </div>
        <div class="flex" v-if="referInfo">
          <div class="reference-con">
            <div class="reference-text">{{referInfo.referText}}</div>
            <span @click="hideReferContent"><van-icon name="cross" class="icon-font cuIcon-roundclosefill"/></span>
          </div>
        </div>
      </div>
    </div>

    <van-dialog v-model="showRepeal" title="温馨提示" show-cancel-button @confirm="sureRepeal">
      确认撤回该消息吗？
    </van-dialog>

    <van-popup v-model="showQuickComments" round position="bottom">
      <div class="title">
        快捷回复
        <div class="hide-btn">
          <div class="hide-btn-item" @click="toEditTemplate">编辑</div>
          <div class="hide-btn-item" @click="hideModel">取消</div>
        </div>
      </div>
      <div class="tab-wrap">
        <van-tabs v-model="currentTag" @change="chooseTag(currentTag)">
          <van-tab v-for="(item,index) in groupList" :key="index" :title="item.name" :name="index">
          </van-tab>
        </van-tabs>
      </div>
      <div class="reply-container">
        <div v-if="replys.length==0" class="empty-wrap">
          <img src="https://img.jimeimadou.com/common/seller_xcx_image/order/null.png"/>
          <div class="text">暂无回复模板</div>
        </div>
        <div v-else class="reply-item-container">
          <div class="reply-item" v-for="(item,index) in replys" :key="index"
               @click="chooseReply(item.content)">{{item.content}}
          </div>
        </div>
      </div>
    </van-popup>
  </div>

</template>

<script>
  import {generateRichTextNode, calcTimeHeader} from "../../util/utils.js";
  import VanImagePre from "vant/lib/image-preview";
  import ScrollY from "../../components/app-scroll-y";

  export default {
    data() {
      return {
        xhsType: this.xhsType,
        showTransferBtn: true,
        mt_id: 0,
        nick: '',
        seller_id: 0,
        modelName: "",
        currentTag: 0,
        chatTo: "",
        groupList: [],
        replys: [],
        order_id: 0,
        orderInfo: {},
        idServer: "",
        endTime: 0,
        height: 0,
        stopLoad: false,
        scrollTop: 0,
        page: 1,
        inputValue: "",
        transfer_money: "",
        balance: 0,
        tip_is_show: false,
        max_trans: 100, //最大转账金额
        errorMsg: "",
        longTapIndex: -1,
        deleteIndex: -1,
        referInfo: null,
        cursorSpacing: 10, //键盘距离输入框的距离
        globalBottom: 0,
        showRepeal: false,
        loading: false,
        showQuickComments: false,
      };
    },
    components: {ScrollY},
    computed: {
      sessionUsers() {
        return this.$store.state.sessionUsers
      },
      messageArr() {
        // console.log("原消息数组格式", this.$store.state.messageArr)
        return this.$store.state.messageArr
      },
      messages() {
        let msgs = this.$store.state.messageArr;
        let users = this.$store.state.sessionUsers;
        return this.handleMessage(msgs, users)
      },
    },
    activated() {
      if (this.$route.query.chatTo) {
        this.chatTo = this.$route.query.chatTo;
        this.seller_id = this.chatTo.split("_")[1];
        // 获取最新订单信息
        this.getOrderInfo();
        // 更新会话对象
        this.$store.dispatch("CurrentChatTo_Change", this.chatTo);
        //获取当前会话对象的聊天信息
        this.getMessages();
        this.getReplyGrouplist();
      }
      if (this.$route.query.nick) {
        this.nick = this.$route.query.nick;
      }
    },
    deactivated() {
      let nim = this.$store.getters.getNim;
      nim.resetSessionUnread("p2p-" + this.chatTo);
      // 更新当前会话对象账户
      this.$store.dispatch('CurrentChatTo_Change', '');
      this.page = 1;
      this.orderInfo = {};
      this.stopLoad = false;
      this.referInfo = null;
    },
    methods: {
      back_list() {
        this.$router.go(-1)
      },

      toMtDetail(type) {
        if (type == "send" || !this.showTransferBtn) {
          return
        }
        // uni.navigateTo({
        //   url: "../model_detail/model_detail?id=" + this.mt_id
        // })
      },

      showTip() {
        this.tip_is_show = !this.tip_is_show
      },

      //区域滚动到底部
      scrollToBottom(y) {
        this.$refs.scroll.scrollToBottom(y+50)
      },

      getOrderInfo() {
        let initparams = {
          access_token: localStorage.getItem('access_token'),
          seller_id: this.seller_id,
        };
        let params = this.$addParams(initparams);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/quickmessage/getorder",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res) => {
          if (res.data.status == 'y') {
            let result = JSON.parse(res.data.data);
            console.log("最新订单信息", result);
            result.mt_price = (result.mt_price / 100).toFixed(2);
            this.orderInfo = result;
            this.order_id = result.order_id
          }
        })
      },

      getMessageList(){
        this.loading = true;
        setTimeout(()=>{
          this.getMessages();
        },1000)
      },
      //获取消息列表
      getMessages() {
        if (this.stopLoad) {
          this.$toast("没有更多了");
          this.loading = false;
          return
        }
        this.height = document.querySelector('.message-container').clientHeight;
        console.log(this.height, "数据没变之前里面内容的高度");
        let nim = this.$store.getters.getNim;
        let sendData = {
          scene: "p2p",
          to: this.chatTo,
          limit: 10,
          asc: true,
          done: (err, data) => {
            this.getMsgDone(err, data)
          }
        };
        //非请求首页数据
        if (this.messages.length != 0) {
          let lastMsg = this.messages[0];
          let idServer = this.idServer ? this.idServer : lastMsg.idServer;
          let endTime = this.endTime ? this.endTime : lastMsg.time;
          sendData.idServer = idServer;
          sendData.endTime = endTime
        }
        nim.getHistoryMsgs(sendData)
      },
      getMsgDone(err, data) {
        if (err) {
          console.log(err);
          return
        }
        if (data.msgs.length == 0) {
          this.stopLoad = true;
          return
        }
        this.idServer = data.msgs[0].idServer;
        this.endTime = data.msgs[0].time;
        //更新聊天信息列表
        this.$store.dispatch("SetCurrentChatMessage", data.msgs).then(() => {
          this.loading = false;
          if (this.page == 1) {
            let height = document.querySelector('.message-container').clientHeight;
            let scrollHeight = document.querySelector('.message-container').scrollHeight;
            console.log(height, scrollHeight, "首页进来的高度");
            this.scrollToBottom(height);
            //发送消息已读回执
            this.sendMsgReceipt(this.messageArr)
          } else {
            let height = document.querySelector('.message-container').clientHeight;
            let scrollHeight = document.querySelector('.message-container').scrollHeight;
            console.log(height, scrollHeight, "请求完成里面内容的高度");
            this.scrollTop = height - this.height;
            this.scrollToBottom(this.scrollTop+500);
          }
          this.page++
        })
      },

      //长按显示操作选项
      showOperation(index) {
        this.longTapIndex = index;
      },
      //关闭操作选项
      hideOperation() {
        this.longTapIndex = -1
      },

      //清除引用内容
      hideReferContent() {
        this.referInfo = null;
        this.cursorSpacing = 10
      },

      //确认撤回消息
      sureRepeal() {
        console.log("开始撤回消息", this.deleteIndex);
        let msg = this.messageArr[this.deleteIndex];
        let nim = this.$store.getters.getNim;
        if (!nim) {
          return
        }
        nim.deleteMsg({
          msg,
          done: (err, result) => {
            this.hideModel();
            // 判断错误类型，并做相应处理
            if (this.handleErrorAfterSend(err)) {
              return
            }
            console.log("撤回的消息是", result.msg);
            //更新聊天信息列表
            this.$store.dispatch('MessageArr_Delete_Mine', result.msg);
            this.longTapIndex = -1;
            this.$toast('消息已撤回')
          }
        })
      },

      //将store里面的数据处理成页面需要的数据格式
      handleMessage(arr, users) {
        if (arr.length == 0) {
          return []
        }
        let newArr = [];
        arr.forEach(item => {
          let displayTimeHeader = this.judgeOverTwoMinute(item.time, newArr);
          let msgItem = {};
          switch (item.type) {
            case "text":
              msgItem.origin_text = item.text;
              msgItem.text = generateRichTextNode(item.text);
              //item.custom包含消息对象则为引用消息
              if (item.custom && JSON.parse(item.custom).time) {
                msgItem = this.handleReferContent(item.custom, msgItem)
              }
              break;
            case "image":
              msgItem.image = item.file;
              break;
            case "video":
              msgItem.video = item.file;
              break;
            case "custom":
              msgItem.content = item.content ? JSON.parse(item.content) : "";
              if (msgItem.content.value) {
                msgItem.content.value = parseFloat(msgItem.content.value).toFixed(2)
              }
              if (msgItem.content.goods_pic) {
                msgItem.content.goods_pic = msgItem.content.goods_pic.split(",")[0]
              }
              break;
            case "tip":
              msgItem.tipMsg = item.tipMsg;
              break;
            default:
              break;
          }
          msgItem.isLastRead = item.isLastRead;
          msgItem.type = item.type;
          msgItem.idServer = item.idServer;
          msgItem.fromNick = item.fromNick;
          msgItem.avatar = users[item.from] ? users[item.from].avatar : "https://img.jimeimadou.com/common/mt_ico.png";
          if (item.flow === "out") {
            msgItem.avatar = this.$store.getters.userInfo && this.$store.getters.userInfo.head_ico || "https://img.jimeimadou.com/common/mt_ico.png";
          }
          msgItem.fromClientType = item.fromClientType;
          msgItem.scene = item.scene;
          msgItem.status = item.status;
          msgItem.time = item.time;
          msgItem.to = item.to;
          msgItem.displayTimeHeader = displayTimeHeader;
          msgItem.sendOrReceive = item.flow == "out" ? "send" : "receive";
          newArr.push(msgItem)
        });
        console.log(newArr);
        return newArr
      },
      //处理引用内容的数据格式
      handleReferContent(content, obj) {
        let customMsg = JSON.parse(content);
        obj.referType = customMsg.type;
        obj.referFromNick = customMsg.fromNick;
        if (customMsg.type == "text") {
          obj.referContent = generateRichTextNode(customMsg.text)
        }
        if (customMsg.type == "image" || customMsg.type == "video") {
          obj.referContent = {
            url: customMsg.fileUrl,
            w: customMsg.fileWidth,
            h: customMsg.fileHeight
          }
        }
        return obj
      },

      showModel() {
        this.showQuickComments = true;
      },
      hideModel() {
        this.showRepeal = false;
        this.showQuickComments = false;
      },

      //预览图片
      previewImage(url) {
        VanImagePre({
          images: [url],
          startPosition: 0,
        });
      },
      toOrderDetail(id) {
        this.$router.push({
          name: 'orderdetail',
          query: {
            id: id
          }
        })
      },
      toVideoDetail(url) {
        this.$router.push({
          name: "videoDetail",
          query: {
            url
          }
        });
      },

      //点击"复制"
      copyContent(index) {
        let msgItem = this.messages[index];
        let content = msgItem.origin_text;
        this.$copyText(content).then(() => {
            this.$toast('复制成功');
          }, () => {
            this.$toast('复制失败');
          }
        )
      },
      //点击"引用"
      referenceContent(index) {
        let referMsg = Object.assign({}, this.messageArr[index]);
        let referText = null;
        if (referMsg.custom) {
          delete referMsg.custom
        }
        if (referMsg.file) {
          referMsg.fileUrl = referMsg.file.url;
          referMsg.fileWidth = referMsg.file.w;
          referMsg.fileHeight = referMsg.file.h;
          delete referMsg.file;
          if (referMsg.type == "image") {
            referText = "[图片]"
          }
          if (referMsg.type == "video") {
            referText = "[视频]"
          }
        } else {
          referText = referMsg.text
        }

        this.referInfo = {
          referMsg,
          referText
        };
        //console.log("引用信息=====", this.referInfo);
      },
      //点击"撤回"
      repealContent(index) {
        this.deleteIndex = index;
        this.showRepeal = true;
      },

      //距离上一条消息是否超过两分钟
      judgeOverTwoMinute(time, messageArr) {
        let displayTimeHeader = '';
        let lastMessage = messageArr[messageArr.length - 1];
        if (lastMessage) { //拥有上一条消息
          let delta = time - lastMessage.time;
          if (delta > 2 * 60 * 1000) { //两分钟以上
            displayTimeHeader = calcTimeHeader(time)
          }
        } else { //没有上一条消息
          displayTimeHeader = calcTimeHeader(time)
        }
        return displayTimeHeader
      },
      //发送消息已读回执
      sendMsgReceipt(msgList) {
        console.log("发送消息已读回执", msgList.length - 1, msgList[msgList.length - 1]);
        let nim = this.$store.getters.getNim;
        nim.sendMsgReceipt({
          msg: msgList[msgList.length - 1],
          done: function (e, t) {
            console.log("发送消息已读回执" + (e ? "失败" : "成功"), e, t)
          }
        });
      },
      //发送自定义消息
      sendCustomMsg() {
        let content = this.orderInfo;
        let self = this;
        let nim = this.$store.getters.getNim;
        nim.sendCustomMsg({
          scene: 'p2p',
          to: this.chatTo,
          content: JSON.stringify(content),
          custom: JSON.stringify(this.order_id),
          done: (err, msg) => {
            console.log(err, msg);
            // 判断错误类型，并做相应处理
            if (self.handleErrorAfterSend(err)) {
              return
            }
            // 存储数据到store
            self.saveChatMessageListToStore(msg)
          }
        })
      },
      //发送文本消息
      inputSend() {
        if (!this.inputValue) {
          this.$toast("请输入内容");
          return
        }
        this.sendRequest(this.inputValue);
      },
      sendRequest(text) {
        //如果输入框下面有引用内容则是发送包含引用内容的文本消息
        let custom_obj = {
          order_id: this.order_id
        };
        if (this.referInfo) {
          custom_obj = Object.assign(custom_obj, this.referInfo.referMsg);
        }
        let nim = this.$store.getters.getNim;
        nim.sendText({
          scene: 'p2p',
          to: this.chatTo,
          text,
          custom: JSON.stringify(custom_obj),
          done: (err, msg) => {
            // 判断错误类型，并做相应处理
            if (this.handleErrorAfterSend(err)) {
              return
            }
            //清空输入框
            this.inputValue = "";
            this.referInfo = null;
            this.cursorSpacing = 10;
            this.$refs.msgInput.blur();
            // 存储数据到store
            this.saveChatMessageListToStore(msg)
          }
        })
      },
      //发送图片到nos
      sendImageToNOS(e) {
        let self = this;
        if (!e.target.files[0]) {
          return;
        }
        this.$toast.loading({
          message: "发送中",
          duration: 0,
        });
        let nim = this.$store.getters.getNim;
        let custom_obj = {
          order_id: this.order_id
        };
        // 上传文件到nos
        nim.sendFile({
          type: 'image',
          scene: 'p2p',
          to: self.chatTo,
          fileInput: document.querySelector('.image-input'),
          custom: JSON.stringify(custom_obj),
          done: function (err, msg) {
            self.$toast.clear();
            // 判断错误类型，并做相应处理
            if (self.handleErrorAfterSend(err)) {
              return
            }
            self.saveChatMessageListToStore(msg)
          }
        })
      },
      //发送视频文件到nos
      sendVideoToNos(e) {
        let self = this;
        if (!e.target.files[0]) {
          return;
        }
        this.$toast.loading({
          message: "发送中",
          duration: 0,
        });
        let nim = this.$store.getters.getNim;
        let custom_obj = {
          order_id: this.order_id
        };
        // 上传文件到nos
        nim.sendFile({
          type: 'video',
          scene: 'p2p',
          to: self.chatTo,
          fileInput: document.querySelector('.video-input'),
          custom: JSON.stringify(custom_obj),
          done: function (err, msg) {
            self.$toast.clear();
            // 判断错误类型，并做相应处理
            if (self.handleErrorAfterSend(err)) {
              return
            }
            self.saveChatMessageListToStore(msg)
          }
        })
      },
      //保存数据到store
      saveChatMessageListToStore(rawMsg) {
        this.$store.dispatch("MessageArr_Update", rawMsg).then(() => {
          let height = document.querySelector('.message-container').clientHeight;
          this.scrollToBottom(height);
        })
      },
      //统一发送消息后打回的错误信息返回true表示有错误，false表示没错误
      handleErrorAfterSend(err) {
        if (err) {
          console.log(err);
          if (err.code == 508) {
            this.$toast('消息发出已超过2分钟，无法撤回')
          }
          return true
        }
        return false
      },

      toEditTemplate() {
        this.$router.push({name: 'editReply'})
      },
      //请求分组列表
      getReplyGrouplist() {
        let initparams = {
          access_token: localStorage.getItem('access_token'),
        };
        let params = this.$addParams(initparams);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/quickmessage/grouplist",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res) => {
          if (res.data.status == 'y') {
            let result = JSON.parse(res.data.data);
            console.log("快捷回复分组列表", result);
            this.groupList = result;
            //处理用户删除分组返回时的情况
            if (this.currentTag > this.groupList.length - 1) {
              this.currentTag = 0;
            }
            this.replys = result.length == 0 ? [] : result[this.currentTag].data;
          }
        })
      },
      //选择快捷回复
      chooseReply(content) {
        this.sendRequest(content);
        this.showQuickComments = false;
      },
      //选择分类
      chooseTag(index) {
        this.currentTag = index;
        this.replys = this.groupList[index].data
      },
    }
  }
</script>

<style lang="scss" scoped>
  * {
    /*-webkit-touch-callout: none; !*系统默认菜单被禁用*!*/
    /*-webkit-user-select: auto; !*webkit浏览器*!*/
    /*-khtml-user-select: none; !*早起浏览器*!*/
    /*-moz-user-select: none; !*火狐浏览器*!*/
    /*-ms-user-select: none; !*IE浏览器*!*/
    /*user-select: none; /*用户是否能够选中文本*/
  }
  .chat-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .chat-title {
    width: 100%;
    height: 80px;
    display: flex;
    font-size: 35px;
    align-items: center;
    padding: 20px;
    justify-content: center;
    position: relative;
    background-color: #fff;

    .back_img {
      position: absolute;
      left: 20px;
      width: 50px;
      height: 50px;
      background-color: rgba(220, 220, 220, 0.5);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 80%;
        width: 80%;
        margin-left: -10px;
        opacity: 0.6;
      }
    }
  }

  .chat-container {
    width: 100vw;
    height: 83vh;
    overflow-anchor: auto;
    background-color: #F2F3F7;

    .message-container {
      padding: 0 24px;
    }

    .empty-container {
      height: 100px;
    }

    .chat-time-header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
    }

    .chat-time-header .time-msg {
      padding: 0 20px;
      line-height: 40px;
      font-size: 24px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      color: #fff;
      user-select: none;
    }

    .message-item {
      display: flex;
      padding: 20px 0;
      user-select: none;
    }

    .message-item .avatar {
      width: 80px;
      height: 80px;
      border-radius: 10px;
      flex-shrink: 0;
    }

    .read-status {
      position: absolute;
      right: calc(100% + 10px);
      bottom: 0;
      font-size: 24px;
      color: #B8BECC;
      white-space: nowrap;
    }

    .message-con {
      margin: 0 24px;
      max-width: 494px;
      position: relative;
    }

    .message-link {
      padding: 20px;
      width: 494px;
      background-color: #fff;
      border-radius: 15px;
      position: relative;
    }

    .task-no {
      font-size: 28px;
      margin-bottom: 10px;
      position: relative;
      top: -5px;
    }

    .task-msg {
      display: flex;
      justify-content: space-between;
    }

    .task-cover {
      width: 150px;
      height: 150px;
      margin-right: 15px;
      border-radius: 12px;
      display: block;
      object-fit: cover;
    }

    .task-msg-right {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 28px;
      .task-price {
        font-size: 24px;
      }
    }

    .task-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 28px;
      line-height: 40px;
    }

    .task-tag-con {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow: hidden;

      .zc-icon {
        display: flex;
        align-items: center;
      }

      .goods_labelimg {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }

      .xhs {
        width: 88px;
        height: 30px;
        display: block;
        margin-right: 6px;
        border-radius: 6px;
      }

      .type-tip {
        border-radius: 5px;
        color: #fff;
        padding: 0 8px;
        font-size: 20px;
        line-height: 30px;
        height: 30px;
        margin-right: 6px;
        font-weight: normal;
        vertical-align: middle;
        display: inline-block;
      }

      .type-tip.type1 {
        background-color: #8AE6A8;
      }

      .type-tip.type2 {
        background-color: #FFEBEF;
        color: #FF6685;
      }

      .type-tip.type3 {
        background-color: #000;
      }

      .type-tip.type4 {
        background-color: #FF6685;
      }
    }

    .text-other {
      position: relative;
      background-color: #fff;
    }

    .text-other::after {
      content: "";
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 40px;
    }

    .text-mine {
      position: relative;
      background-color: #95EC69;
    }

    .text-mine::after {
      content: "";
      width: 20px;
      height: 20px;
      transform: translate(50%, -50%) rotate(45deg);
      background-color: #95EC69;
      position: absolute;
      right: 0;
      top: 40px;
    }

    .link-mine::after {
      content: "";
      width: 20px;
      height: 20px;
      transform: translate(50%, -50%) rotate(45deg);
      background-color: #fff;
      position: absolute;
      right: 0;
      top: 40px;
    }

    .link-other::after {
      content: "";
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 40px;
    }

    .emoji-icon {
      width: 40px;
      height: 40px;
      vertical-align: middle;
      position: relative;
      top: -4px;
      display: inline-block;
    }

    .custom-money {
      width: 450px;
      padding: 20px 20px 15px;
      background-color: #FA9B3B;
      color: #fff;
      border-radius: 15px;
      position: relative;
      .flex {
        display: flex;
      }
      .iconfont {
        font-size: 66px;
        margin-right: 15px;
      }
      .money-value {
        font-size: 32px;
      }
      .money-name {
        font-size: 24px;
      }
    }

    .custom-money::before {
      content: "";
      width: 20px;
      height: 20px;
      transform: translate(50%, -50%) rotate(45deg);
      background-color: #FA9B3B;
      position: absolute;
      left: -18px;
      top: 40px;
    }

    .custom-money-tip {
      border-top: 1px solid #fff;
      font-size: 24px;
      margin-top: 10px;
      line-height: 40px;
    }

    .message-text {
      border-radius: 15px;
      line-height: 40px;
      padding: 20px 24px;
      color: #000;
      font-size: 28px;
      word-wrap: break-word;
      position: relative;
      pointer-events:none;
      // user-select: text !important;
    }

    .video-position {
      position: relative;
      width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .message-image {
      border-radius: 10px;
      display: block;
      max-width: 300px;
      height: 300px;
      pointer-events:none;
    }
    .message-video {
      border-radius: 10px;
      display: block;
      max-width: 350px;
      height: 350px;
    }

    .play-icon {
      width: 60px;
      height: auto;
      display: block;
      position:absolute;
    }
    .operation-wrap {
      position: absolute;
      bottom: calc(100% + 15px);
      left: 50%;
      transform: translateX(-50%) scale(0);
      transition: 0.2s;

      &.operation-show {
        transform: translateX(-50%) scale(1);
      }

      .operation-con {
        position: relative;
        display: flex;
        align-items: center;
        padding: 12px 10px;
        line-height: 40px;
        border-radius: 10px;
        background-color: #000;
        color: #fff;
        font-size: 24px;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%) rotate(45deg);
          background-color: #000;
          width: 12px;
          height: 12px;
        }

        .copy, .repeal {
          display: flex;
        }

        .line {
          width: 1px;
          height: 32px;
          background-color: #fff;
          opacity: 0.5;
        }

        .operation-item {
          width: 80px;
          text-align: center;
        }
      }
    }
    .msg-reference-wrap {
      margin: 0 104px;
      position: relative;
      top: -10px;
      display: flex;

      &.justify-end {
        justify-content: flex-end;
      }

      &.justify-start {
        justify-content: flex-start;
      }

      .msg-reference-con {
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        display: flex;
        line-height: 32px;
        font-size: 24px;
        color: #fff;

        .msg-reference-nick {
          white-space: nowrap;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .flex-sub {
          .reference-img, .reference-video {
            display: block;
            border-radius: 5px;
            width: 100px;
            height: auto;
          }

          .msg-reference {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
        }
        .video-wrap {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          .play-icon {
            position: absolute;
            width: 32px;
          }
        }
      }
    }
  }

  .bottom-container {
    position: fixed;
    bottom: 0;
    background-color: #F6F6F6;
    padding: 20px 24px 10px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
    .link-container {
      display: flex;
      justify-content: space-between;
    }

    .link-item {
      max-width: 400px;
      display: flex;
      align-items: center;
      line-height: 46px;
      border-radius: 23px;
      padding: 0 20px;
      font-size: 24px;
      color: #5B5F66;
      border: 1px solid #DCDDE0;
    }

    .link-icon {
      font-size: 24px;
      color: #5B5F66;
      margin-right: 6px;
    }

    .link-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .bottom-input-container {
      padding: 20px 0;
      .flex {
        margin-top: 15px;
      }
    }
    .bottom-input {
      display: flex;
      align-items: center;

      .iconfont {
        display: block;
        position: relative;

        input {
          position: absolute;
          width: 54px;
          height: 54px;
          opacity: 0;
          top: 0;
          left: 0;
        }
      }
    }

    .input-container {
      flex: 1;
      position: relative;
    }

    .input-container input {
      background-color: #fff;
      height: 80px;
      width: 100%;
      border-radius: 15px;
      padding: 0 80px 0 20px;
      box-sizing: border-box;
      font-size: 28px;
    }

    .input-container .input-icon {
      font-size: 48px;
      color: #FF6685;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    .media-icon {
      margin-left: 20px;
      font-size: 54px;
      margin-left: 20px;
    }

    .reply-con {
      height: 900px;
      display: flex;
      flex-direction: column;

      .reply-title {
        line-height: 100px;
        font-weight: 700;
        text-align: center;
        color: #000;
        font-size: 32px;
        position: relative;

        .hide-btn {
          display: flex;
          font-weight: normal;
          font-size: 28px;
          color: #262A33;
          position: absolute;
          right: 0;
          top: 0;

          .hide-btn-item {
            padding: 0 15px;
            margin-right: 10px;
          }
        }
      }

      .tag-container {
        padding: 0 24px;
      }

      .tag-item-container {
        display: flex;
        flex-wrap: nowrap;
      }

      .tag-item {
        padding: 0 20px;
        line-height: 60px;
        border-radius: 30px;
        font-size: 26px;
        border: 1px solid #EFEFEF;
        margin-right: 20px;
        white-space: nowrap;
      }

      .tag-item:last-child {
        margin-right: 0;
      }

      .tag-item.tag-current {
        background-color: #FF6685;
        border-color: #FF6685;
        color: #fff;
      }

      .reply-item:last-child {
        margin-bottom: 100px;
      }

    }

    .model-con {
      padding: 40px 40px 50px;

      .model-title {
        line-height: 60px;
        font-size: 32px;
        font-weight: 700;
        color: #000;
        position: relative;
      }

      .model-title .cuIcon-questionfill {
        color: rgba(0, 0, 0, 0.3);
        position: absolute;
        left: calc(100% + 15px);
        top: 0;
        line-height: 60px;
      }

      .model-title .tip-content {
        position: absolute;
        left: 100px;
        top: 80px;
        width: 300px;
        font-size: 24px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
        line-height: normal;
        padding: 15px 20px;
        text-align: left;
        z-index: 100;
        font-weight: normal;
      }

      .input-money-con {
        margin: 20px 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f7f7f7;

        .transfer-input-con {
          height: 80px;
          line-height: 80px;
          text-align: left;
          color: #000;

          .transfer-input {
            padding: 0 15px;
            height: 80px;
            font-size: 48px;
            color: #000;
          }
        }

        .error-text {
          text-align: left;
          font-size: 24px;
        }
      }

      .my-balance {
        line-height: 60px;
        height: 60px;
        border-radius: 30px;
        padding: 0 20px;
        background-color: #F7f7f7;
        color: #666;
        font-size: 26px;
      }

      .model-btn-con {
        view {
          width: 220px;
          line-height: 70px;
          border-radius: 35px;
          border: 1px solid #ddd;
          font-size: 32px;
        }

        .model-btn-item-r {
          background-color: #000000;
          color: #fff;
          border-color: #000000 !important;
          margin-left: 50px;
        }
      }
    }

    .reference-con {
      font-size: 22px;
      color: #aaa;
      border-radius: 10px;
      padding: 10px 15px;
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      padding-right: 50px;
      position: relative;

      .reference-text {
        line-height: 30px;
        max-height: 60px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }

      .cuIcon-roundclosefill {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 26px;
      }
    }
  }

  .van-loading {
    padding: 8px;
    text-align: center;
    .van-loading__spinner {
      width: 40px;
      height: 40px;
    }
    .van-loading__text {
      font-size: 26px;
    }
  }

  /* 弹框相关 */

  ::v-deep .van-overlay {
    background-color: rgba(0, 0, 0, .5);
  }

  .van-dialog {
    width: 570px;
    font-size: 30px;
    padding: 30px 40px;

    ::v-deep .van-dialog__header {
      font-size: 34px;
      font-weight: 600;
      padding-top: 15px;
    }

    ::v-deep .van-dialog__content {
      margin-top: 40px;
      text-align: center;
      line-height: 40px;
    }

    ::v-deep .van-dialog__footer {
      margin-top: 50px;
      justify-content: center;

      .van-button {
        font-size: 32px;
        height: 68px;
        border-radius: 50px;
        flex: none;
        width: 220px;

        &.van-dialog__confirm {
          background-color: #000;
          color: #fff;
        }

        &.van-dialog__cancel {
          border: 1px solid #8c8c8c;
          margin-right: 40px;
        }
      }
    }
  }

  ::v-deep .van-popup {
    padding: 40px;
    text-align: center;
    height: 66%;

    .title {
      font-size: 35px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 40px;
      display: flex;
      justify-content: flex-end;
      .hide-btn{
        font-size: 28px;
        font-weight: 400;
        display: flex;
        width: 40%;
        justify-content: flex-end;
        line-height: 35px;
        .hide-btn-item {
          margin-left: 30px;
        }
      }
    }

    .tab-wrap {
      .van-tabs__wrap {
        height: 64px;

        .van-tab {
          width: 110px;
          border: 1px solid #cccccc;
          border-radius: 50px;
          flex: none;
          margin-right: 20px;

          .van-tab__text {
            font-size: 28px;
            line-height: 40px;
          }

          &.van-tab--active {
            color: #fff;
            background-color: #FF6685;
            border-color: #FF6685;
          }
        }

        .van-tabs__line {
          display: none;
        }
      }
    }

    .reply-container {
      margin-top: 20px;
      .empty-wrap {
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 300px;
          height: auto;
          display: block;
        }

        .text {
          margin-top: 20px;
          color: #aaa;
          font-size: 28px;
        }
      }

      .reply-item-container {
        text-align: left;

        .reply-item {
          margin-bottom: 15px;
          background-color: #F6F6F6;
          border-radius: 10px;
          font-size: 26px;
          padding: 15px 20px;
          color: #262A33;
          line-height: 40px;
        }
      }
    }
  }

</style>
