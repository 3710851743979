<template>
  <div class="message-wrap" @click="hideOperation">
    <div class="msg_title">
      消息列表
    </div>
    <div v-if="!chatList.length" class="empty-wrap">
      <img src="https://img.jimeimadou.com/common/seller_xcx_image/order/null.png" style=""/>
      <div class="empty-tip">暂无消息</div>
    </div>
    <div v-else class="session-container">
      <template v-for="(item, index) in chatList">
        <div class="news-item" :class="item.isTop ? 'bg-top' : ''" :key="index">
          <div class="item-wrap" @click="toChatDetail(item.to,item.nick)" v-longpress="() => showOperation(index)">
            <div class="avatar-wrap">
              <img class="avatar" :src="item.avatar"/>
              <div class="unread" v-if="item.unread && !item.isMute">
                {{item.unread}}</div>
              <div class="unread mute" v-if="item.unread && item.isMute"></div>
            </div>
            <div class="mt-info flex-sub">
              <div class="nick-con">
                <div class="nickname">{{item.nick ? item.nick : item.to}}</div>
                <div class="update-time">{{item.lastMsgTime}}</div>
              </div>
              <div class="nick-con">
                <div class="chat-txt">{{item.lastMsg.text}}</div>
                <span v-if="item.isMute" class="iconfont icon-jingyin"></span>
              </div>
            </div>

            <div :class="['operation_wrap', longTapIndex==index ? 'operation_show' : '']">
              <div class="operation_con">
                <div class="operation_item" v-for="(btn,bindex) in item.button" :key="bindex"
                     @click.stop.prevent="handleClick(bindex, btn.data)">
                  <span>{{btn.text}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <van-dialog v-model="showDelete" title="温馨提示" show-cancel-button @confirm="catchDeleteTap">
      确认删除这条聊天记录吗?
    </van-dialog>

  </div>
</template>

<script>
  import {calcTimeHeader} from '../util/utils'
  export default {
    name: "Message",
    data() {
      return {
        optionsIndex: -1,
        select_session: null,
        longTapIndex: -1,
        showDelete: false,
      };
    },
    computed: {
      chatList() {
        //console.log("消息列表页数据更新", this.$store.state);
        let sessionList = this.$store.state.sessionList;
        let users = this.$store.state.sessionUsers;
        let stickTopSessions = this.$store.state.stickTopSessions;
        let muteSessions = this.$store.state.muteSessions;
        return this.handleSessions(sessionList, users, stickTopSessions, muteSessions)
      }
    },
    methods: {
      toChatDetail(account,nick) {
        if(this.longTapIndex > -1){
          this.hideOperation();
          return;
        }
        this.$router.push({
          name: 'chat',
          query:{
            chatTo: account,
            nick: nick
          }
        });
      },

      handleClick(index, data) {
        this.select_session = data.session;
        this.optionsIndex = index;
        switch (this.optionsIndex) {
          case 0:
            let isTop = data.isTop;
            this.setTopSession(isTop);
            break;
          case 1:
            this.showDelete = true;
            this.hideOperation();
            break;
          case 2:
            let isMute = data.isMute;
            this.addToMuteSession(isMute);
            break;
          default:
            break;
        }
      },

      //会话置顶/取消
      setTopSession(isTop) {
        let nim = this.$store.getters.getNim;
        const _this = this;
        if (isTop) {
          nim.deleteStickTopSession({
            id: "p2p-" + this.select_session,
            done: function(error) {
              _this.$toast("已取消置顶");
              _this.hideOperation();
              console.log('已取消置顶', _this.longTapIndex);
            }
          })
        } else {
          nim.addStickTopSession({
            id: "p2p-" + this.select_session,
            done: function(error) {
              _this.$toast("置顶成功");
              _this.hideOperation();
              console.log('置顶成功', _this.longTapIndex);
            }
          })
        }
        this.$store.dispatch("StickTopSessions_Update", {
          session: this.select_session,
          isTop
        })
      },

      //添加、移除免打扰
      addToMuteSession(isMute) {
        let nim = this.$store.getters.getNim;
        let self = this;
        if (isMute) {
          nim.removeFromMutelist({
            account: this.select_session,
            done: function(error, msg) {
              if (!error) {
                self.$toast("已设置消息提醒");
                self.$store.dispatch("Mutelist_Update", {
                  msg,
                  act: "del"
                })
              } else {
                console.log(error)
              }
              self.hideOperation()
            }
          })
        } else {
          nim.addToMutelist({
            account: this.select_session,
            done: function(error, msg) {
              if (!error) {
                self.$toast("已设置免打扰");
                self.$store.dispatch("Mutelist_Update", {
                  msg,
                  act: "add"
                })
              } else {
                console.log(error)
              }
              self.hideOperation()
            }
          })
        }
      },

      //删除会话
      catchDeleteTap() {
        let nim = this.$store.getters.getNim;
        let self = this;
        nim.deleteSession({
          scene: "p2p",
          to: this.select_session,
          done: function deleteServerSessionDone(error) {
            if (error) {
              console.log(error);
              return
            }
            self.showDelete = false;
            self.$toast("聊天已删除");
            self.$store.dispatch("deleteSessions", "p2p-" + self.select_session)
          }
        })
      },

      //处理会话列表数据
      handleSessions(sessionList, users, stickTopSessions, muteSessions) {
        if (!sessionList || !sessionList.length) {
          return []
        }
        let topList = [],
          noTopList = [];
        sessionList.forEach(item => {
          item.isTop = stickTopSessions[item.to] || false;
          item.isMute = muteSessions[item.to] || false;
          item.lastMsg.text = this.handleLastMsg(item.lastMsg);
          item.lastMsgTime = this.handleUpdateTime(item.updateTime);
          if (users[item.to]) {
            item.nick = users[item.to].nick || "暂无昵称";
            item.avatar = users[item.to].avatar || "https://img.jimeimadou.com/common/mt_ico.png"
          }

          item.button = [{
            text: item.isTop ? '取消置顶' : '置顶',
            data: {
              session: item.to,
              isTop: item.isTop
            }
          }, {
            text: '删除',
            data: {
              session: item.to
            }
          }, {
            text: item.isMute ? '消息提醒' : '免打扰',
            data: {
              session: item.to,
              isMute: item.isMute
            }
          }];
          if (item.isTop) {
            topList.push(item);
            topList.sort((a, b) => {
              return b.updateTime - a.updateTime
            })
          } else {
            noTopList.push(item);
            noTopList.sort((a, b) => {
              return b.updateTime - a.updateTime
            })
          }

        });
        let newSessionList = [...topList, ...noTopList];
        return newSessionList
      },

      handleUpdateTime(time) {
        let today = new Date().setHours(0, 0, 0, 0); //获取当天0点的时间
        if (time < today && today - time <= 24 * 60 * 60 * 1000) {
          return "昨天"
        } else if (time >= today) {
          return calcTimeHeader(time)
        } else {
          let date = new Date(time);
          let year = date.getFullYear();
          let month = (date.getMonth() + 1).toString().padStart(2, '0');
          let day = date.getDate().toString().padStart(2, '0');
          return year + "/" + month + "/" + day
        }
      },
      
      //处理用户会话最后一条消息
      handleLastMsg(msg) {
        let text = "";
        switch (msg.type) {
          case "text":
            text = msg.text;
            break;
          case "image":
            text = "[图片]";
            break;
          case "video":
            text = "[视频]";
            break;
          case "custom":
            if (JSON.parse(msg.content).value) {
              text = "[转账消息]"
            } else {
              text = "[订单卡片]"
            }
            break;
          default:
            break;
        }
        return text
      },

      //长按显示操作选项
      showOperation(index) {
        console.log('长按', index);
        this.longTapIndex = index;
      },
      //关闭操作选项
      hideOperation() {
        this.longTapIndex = -1
      },
    }
  }
</script>

<style lang="scss" scoped>

  * {
    -webkit-touch-callout: none; /*系统默认菜单被禁用*/
    -webkit-user-select: none; /*webkit浏览器*/
    -khtml-user-select: none; /*早起浏览器*/
    -moz-user-select: none; /*火狐浏览器*/
    -ms-user-select: none; /*IE浏览器*/
    user-select: none; /*用户是否能够选中文本*/
  }
  .message-wrap {
    background-color: #fff;
    height: 100vh;
    .msg_title {
      width: 100%;
      height: 80px;
      font-size: 35px;
      display: flex;
      align-items: center;
      padding: 20px;
      justify-content: center;
      box-shadow: 0 0 0.26667rem 0.01333rem rgb(0 0 0 / 5%);
    }
    .empty-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 80vh;
      img {
        width: 300px;
        height:auto;
        display:block;
      }
      .empty-tip {
        margin-top: 20px;
        color: #aaa;
        font-size: 30px;
      }
    }
    .session-container {
      position: fixed;
      top: 82px;
      width: 100%;
      background-color: #fff;
      z-index: 1000;
      .news-item {
        padding: 15px 24px;
        border-bottom: 1px solid #F1F1F1;
        .item-wrap {
          position: relative;
          display: flex;
          justify-content: space-between;
        }
        .avatar-wrap {
          position: relative;
          margin-right: 15px;
          width: 100px;
          .avatar {
            width: 100px;
            height: 100px;
            border-radius: 10px;
            display: block;
          }
          .unread {
            width: 36px;
            height: 36px;
            line-height: 36px;
            border-radius: 18px;
            font-size: 24px;
            color: #fff;
            background-color: #FF6685;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(40%, -40%);
            text-align: center;
            &.mute {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
          }
        }

        .mt-info {
          line-height: 50px;
          overflow: hidden;
          flex:1;
          .nick-con {
            display: flex;
            justify-content: space-between;
            .nickname {
              font-size: 30px;
              font-weight: 700;
              max-width: 500px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .update-time {
              color: #888;
              font-size: 24px;
            }
            .chat-txt {
              font-size: 28px;
              color: #aaa;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 500px;
            }
            .iconfont {
              color: #aaa;
              font-size: 28px;
              font-weight: bold
            }
          }
        }

        .operation_wrap {
          position: absolute;
          left: 50%;
          bottom: -90%;
          display: none;
          transition: 0.2s;
          z-index: 1;

          &.operation_show {
            display: block;
          }

          .operation_con {
            position: relative;
            padding: 12px 10px;
            line-height: 50px;
            border-radius: 10px;
            background-color: #000;
            color: #fff;
            font-size: 24px;

            .operation_item {
              width: 120px;
              text-align: center;
              display: block;
              span {
                font-size: 27px;
              }
            }
          }
        }

        &.bg-top {
          background-color: #F1F1F1;
        }
      }
    }
  }

  .model-con {
    width: 600px;
    padding: 40px;
    .model-title {
      font-size: 32px;
      font-weight: 700;
      color: #000;
    }

    .model-content {
      padding: 35px 0 50px;
    }

    .model-btn-con>view {
      width: 240px;
      line-height: 70px;
      border-radius: 35px;
      border: 1px solid #ddd;
      font-size: 32px;
    }

    .model-btn-item-r {
      background-color: #000000;
      color: #fff;
      border-color: #000000 !important;
      margin-left: 30px;
    }
  }

  /* 弹框相关 */

  ::v-deep .van-overlay {
    background-color: rgba(0, 0, 0, .6);
  }

  ::v-deep .van-dialog {
    width: 570px;
    font-size: 30px;
    padding: 30px 40px;

    .van-dialog__header {
      font-size: 34px;
      font-weight: 600;
      padding-top: 15px;
    }

    .van-dialog__content {
      margin-top: 40px;
      text-align: center;
      line-height: 40px;
    }

    .van-dialog__footer {
      margin-top: 50px;
      justify-content: center;

      .van-button {
        font-size: 32px;
        height: 68px;
        border-radius: 50px;
        flex: none;
        width: 220px;

        &.van-dialog__confirm {
          background-color: #000;
          color: #fff;
        }

        &.van-dialog__cancel {
          border: 1px solid #8c8c8c;
          margin-right: 40px;
        }
      }
    }
  }

</style>