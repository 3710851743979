let emojiBaseUrl = `https://imgcache.qq.com/open/qcloud/tim/assets/`
const iconEmojiDelete =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAk1BMVEUAAADAwMC3t7e/v7/Gxsa/v7+/v7/AwMC/v7+/v7+/v7++vr7CwsK/v7+/v7+/v7++vr6/v7+/v7+/v7+/v7+/v7/AwMCbm5u/v7+/v7+/v7+/v7/AwMC/v7+/v7+/v7+/v7+/v7+/v7++vr7AwMC9vb2/v7+/v7/CwsLAwMDHx8fExMTIyMjLy8vJycnKysrPz8+3r5/kAAAAKHRSTlMA8Q39A/vcIwf34B0P1uNJIuzPjHFqFwVFmls4J761rMiFoVJ6XTNYKpTiKQAAAylJREFUeF7tmEl3ozAQhNt0ywYM3rfETuJMltEC2P//1w3IVg555olmHjfVlcMnAVUtFfgVFBQUFBQUFBREXAAR8BVnEUNZzF8YwTYdsZQ/Pcc8CsGmNEpyZMoqn/MYzxoFomIIhTDFujtjD3OlEFEyVENqzHUN1HUfx0QiKjliKJEVIorioxuF4LQ0AkW53mddf7Asmh5eNaLQeUfGLtUN4w8wtbaULlshiFZVw3gF7g8Jr4UQ5ZMfQkAvl5pRnWO+teYGhT53YMBbWTP0OAMCrqaJQjmLwKunhmGWkx4MiEZSyZEPQvDeMGRy7MOAbCTRCyHYFqqx+Rxi6KHIC7FhYrCW/gKCQSAuTBRWGwaDCSE4CmmNDtRhJhFxIS5M2o1uweQZmw7iC5N2o58O9EMhyDabjAdxYdJudIINytXiTiGY5tfrKuJBXJjo8a6FsUiMuIynQDfGuEqScgvEgLgwaTU6wVEJFEVDsYxCoOBBXJjI5LvdIOeyoaRTiGGaNowqZ7wuFybYYnT3vlJ928t9H3o2AeoOIdhUHqNbykzb9R/y6jfDDyH40g3jYo3uo6CUEh2jM4TgYE8mv4z+kJIWeFORToDn+FUhEI1/ohNMxtIy5NgyGJBspBDlMvKnYtzMV1SIxo6CYXeiZG69P8g3mbhvIqrGlQyIG1Xi8ukobYzb32UkOu93hrihi6i3QB5G48aD9aKl8B2vlPyAvYehZyc4WZqjMLMLFR5aKdHN5+kC4nvClGcA4Kdwe0ISbMs7g35yTB6B+swTs1wAtUCSpLBZcnt3lTBJTew1GXX+eGrVj69X5/OGkhu5Aeo54y+rGOjh489Py3drmn//z2nlBR5TPKcV5rnr7SEF9nvGuct3HW2uMVzxz8KifAcaDOICRhVboMEg7kiB5m/f+4nyQ34CRtmAGWAnLmD637V2I2Vn7I+8AXPiF0sHiWjyGDrf43W663WPv7wBdW8kqlXUp5Eonn9BfAGTRYxuZX7vVmJGSyQQTcJpiUxLS+QJGKW4fRfavovX3Clmc6f1mtlBXuSQHaSjLAdoU4fvhYdvuIOCgoKCgoL+AXU1fW2kbFGNAAAAAElFTkSuQmCC'
let albumArr = []
let emojiList = {
	"emoji": {
		"[NO]": {
			file: "emoji_0@2x.png"
		},
		"[OK]": {
			file: "emoji_1@2x.png"
		},
		"[下雨]": {
			file: "emoji_2@2x.png"
		},
		"[么么哒]": {
			file: "emoji_3@2x.png"
		},
		"[乒乓]": {
			file: "emoji_4@2x.png"
		},
		"[便便]": {
			file: "emoji_5@2x.png"
		},
		"[信封]": {
			file: "emoji_6@2x.png"
		},
		"[偷笑]": {
			file: "emoji_7@2x.png"
		},
		"[傲慢]": {
			file: "emoji_8@2x.png"
		},
		"[再见]": {
			file: "emoji_9@2x.png"
		},
		"[冷汗]": {
			file: "emoji_10@2x.png"
		},
		"[凋谢]": {
			file: "emoji_11@2x.png"
		},
		"[刀]": {
			file: "emoji_12@2x.png"
		},
		"[删除]": {
			file: "emoji_13@2x.png"
		},
		"[勾引]": {
			file: "emoji_14@2x.png"
		},
		"[发呆]": {
			file: "emoji_15@2x.png"
		},
		"[发抖]": {
			file: "emoji_16@2x.png"
		},
		"[可怜]": {
			file: "emoji_17@2x.png"
		},
		"[可爱]": {
			file: "emoji_18@2x.png"
		},
		"[右哼哼]": {
			file: "emoji_19@2x.png"
		},
		"[右太极]": {
			file: "emoji_20@2x.png"
		},
		"[右车头]": {
			file: "emoji_21@2x.png"
		},
		"[吐]": {
			file: "emoji_22@2x.png"
		},
		"[吓]": {
			file: "emoji_23@2x.png"
		},
		"[咒骂]": {
			file: "emoji_24@2x.png"
		},
		"[咖啡]": {
			file: "emoji_25@2x.png"
		},
		"[啤酒]": {
			file: "emoji_26@2x.png"
		},
		"[嘘]": {
			file: "emoji_27@2x.png"
		},
		"[回头]": {
			file: "emoji_28@2x.png"
		},
		"[困]": {
			file: "emoji_29@2x.png"
		},
		"[坏笑]": {
			file: "emoji_30@2x.png"
		},
		"[多云]": {
			file: "emoji_31@2x.png"
		},
		"[大兵]": {
			file: "emoji_32@2x.png"
		},
		"[大哭]": {
			file: "emoji_33@2x.png"
		},
		"[太阳]": {
			file: "emoji_34@2x.png"
		},
		"[奋斗]": {
			file: "emoji_35@2x.png"
		},
		"[奶瓶]": {
			file: "emoji_36@2x.png"
		},
		"[委屈]": {
			file: "emoji_37@2x.png"
		},
		"[害羞]": {
			file: "emoji_38@2x.png"
		},
		"[尴尬]": {
			file: "emoji_39@2x.png"
		},
		"[左哼哼]": {
			file: "emoji_40@2x.png"
		},
		"[左太极]": {
			file: "emoji_41@2x.png"
		},
		"[左车头]": {
			file: "emoji_42@2x.png"
		},
		"[差劲]": {
			file: "emoji_43@2x.png"
		},
		"[弱]": {
			file: "emoji_44@2x.png"
		},
		"[强]": {
			file: "emoji_45@2x.png"
		},
		"[彩带]": {
			file: "emoji_46@2x.png"
		},
		"[彩球]": {
			file: "emoji_47@2x.png"
		},
		"[得意]": {
			file: "emoji_48@2x.png"
		},
		"[微笑]": {
			file: "emoji_49@2x.png"
		},
		"[心碎了]": {
			file: "emoji_50@2x.png"
		},
		"[快哭了]": {
			file: "emoji_51@2x.png"
		},
		"[怄火]": {
			file: "emoji_52@2x.png"
		},
		"[怒]": {
			file: "emoji_53@2x.png"
		},
		"[惊恐]": {
			file: "emoji_54@2x.png"
		},
		"[惊讶]": {
			file: "emoji_55@2x.png"
		},
		"[憨笑]": {
			file: "emoji_56@2x.png"
		},
		"[手枪]": {
			file: "emoji_57@2x.png"
		},
		"[打哈欠]": {
			file: "emoji_58@2x.png"
		},
		"[抓狂]": {
			file: "emoji_59@2x.png"
		},
		"[折磨]": {
			file: "emoji_60@2x.png"
		},
		"[抠鼻]": {
			file: "emoji_61@2x.png"
		},
		"[抱抱]": {
			file: "emoji_62@2x.png"
		},
		"[抱拳]": {
			file: "emoji_63@2x.png"
		},
		"[拳头]": {
			file: "emoji_64@2x.png"
		},
		"[挥手]": {
			file: "emoji_65@2x.png"
		},
		"[握手]": {
			file: "emoji_66@2x.png"
		},
		"[撇嘴]": {
			file: "emoji_67@2x.png"
		},
		"[擦汗]": {
			file: "emoji_68@2x.png"
		},
		"[敲打]": {
			file: "emoji_69@2x.png"
		},
		"[晕]": {
			file: "emoji_70@2x.png"
		},
		"[月亮]": {
			file: "emoji_71@2x.png"
		},
		"[棒棒糖]": {
			file: "emoji_72@2x.png"
		},
		"[汽车]": {
			file: "emoji_73@2x.png"
		},
		"[沙发]": {
			file: "emoji_74@2x.png"
		},
		"[流汗]": {
			file: "emoji_75@2x.png"
		},
		"[流泪]": {
			file: "emoji_76@2x.png"
		},
		"[激动]": {
			file: "emoji_77@2x.png"
		},
		"[灯泡]": {
			file: "emoji_78@2x.png"
		},
		"[炸弹]": {
			file: "emoji_79@2x.png"
		},
		"[熊猫]": {
			file: "emoji_80@2x.png"
		},
		"[爆筋]": {
			file: "emoji_81@2x.png"
		},
		"[爱你]": {
			file: "emoji_82@2x.png"
		},
		"[爱心]": {
			file: "emoji_83@2x.png"
		},
		"[爱情]": {
			file: "emoji_84@2x.png"
		},
		"[猪头]": {
			file: "emoji_85@2x.png"
		},
		"[猫咪]": {
			file: "emoji_86@2x.png"
		},
		"[献吻]": {
			file: "emoji_87@2x.png"
		},
		"[玫瑰]": {
			file: "emoji_88@2x.png"
		},
		"[瓢虫]": {
			file: "emoji_89@2x.png"
		},
		"[疑问]": {
			file: "emoji_90@2x.png"
		},
		"[白眼]": {
			file: "emoji_91@2x.png"
		},
		"[皮球]": {
			file: "emoji_92@2x.png"
		},
		"[睡觉]": {
			file: "emoji_93@2x.png"
		},
		"[磕头]": {
			file: "emoji_94@2x.png"
		},
		"[示爱]": {
			file: "emoji_95@2x.png"
		},
		"[礼品袋]": {
			file: "emoji_96@2x.png"
		},
		"[礼物]": {
			file: "emoji_97@2x.png"
		},
		"[篮球]": {
			file: "emoji_98@2x.png"
		},
		"[米饭]": {
			file: "emoji_99@2x.png"
		},
		"[糗大了]": {
			file: "emoji_100@2x.png"
		},
		"[红双喜]": {
			file: "emoji_101@2x.png"
		},
		"[红灯笼]": {
			file: "emoji_102@2x.png"
		},
		"[纸巾]": {
			file: "emoji_103@2x.png"
		},
		"[胜利]": {
			file: "emoji_104@2x.png"
		},
		"[色]": {
			file: "emoji_105@2x.png"
		},
		"[药]": {
			file: "emoji_106@2x.png"
		},
		"[菜刀]": {
			file: "emoji_107@2x.png"
		},
		"[蛋糕]": {
			file: "emoji_108@2x.png"
		},
		"[蜡烛]": {
			file: "emoji_109@2x.png"
		},
		"[街舞]": {
			file: "emoji_110@2x.png"
		},
		"[衰]": {
			file: "emoji_111@2x.png"
		},
		"[西瓜]": {
			file: "emoji_112@2x.png"
		},
		"[调皮]": {
			file: "emoji_113@2x.png"
		},
		"[象棋]": {
			file: "emoji_114@2x.png"
		},
		"[跳绳]": {
			file: "emoji_115@2x.png"
		},
		"[跳跳]": {
			file: "emoji_116@2x.png"
		},
		"[车厢]": {
			file: "emoji_117@2x.png"
		},
		"[转圈]": {
			file: "emoji_118@2x.png"
		},
		"[鄙视]": {
			file: "emoji_119@2x.png"
		},
		"[酷]": {
			file: "emoji_120@2x.png"
		},
		"[钞票]": {
			file: "emoji_121@2x.png"
		},
		"[钻戒]": {
			file: "emoji_122@2x.png"
		},
		"[闪电]": {
			file: "emoji_123@2x.png"
		},
		"[闭嘴]": {
			file: "emoji_124@2x.png"
		},
		"[闹钟]": {
			file: "emoji_125@2x.png"
		},
		"[阴险]": {
			file: "emoji_126@2x.png"
		},
		"[难过]": {
			file: "emoji_127@2x.png"
		},
		"[雨伞]": {
			file: "emoji_128@2x.png"
		},
		"[青蛙]": {
			file: "emoji_129@2x.png"
		},
		"[面条]": {
			file: "emoji_130@2x.png"
		},
		"[鞭炮]": {
			file: "emoji_131@2x.png"
		},
		"[风车]": {
			file: "emoji_132@2x.png"
		},
		"[飞吻]": {
			file: "emoji_133@2x.png"
		},
		"[飞机]": {
			file: "emoji_134@2x.png"
		},
		"[饥饿]": {
			file: "emoji_135@2x.png"
		},
		"[香蕉]": {
			file: "emoji_136@2x.png"
		},
		"[骷髅]": {
			file: "emoji_137@2x.png"
		},
		"[麦克风]": {
			file: "emoji_138@2x.png"
		},
		"[麻将]": {
			file: "emoji_139@2x.png"
		},
		"[鼓掌]": {
			file: "emoji_140@2x.png"
		},
		"[龇牙]": {
			file: "emoji_141@2x.png"
		}
	}
}

for (let emoji in emojiList) {
	let emojiItem = emojiList[emoji]
	for (let key in emojiItem) {
		let item = emojiItem[key]
		item.img = `${emojiBaseUrl}/${emoji}/${item.file}`
	}
}

emojiList['ajmd'] = {}
emojiList['xxy'] = {}
emojiList['lt'] = {}

for (let i = 1; i <= 48; i++) {
	let key = 'ajmd0' + (i >= 10 ? i : '0' + i)
	emojiList['ajmd'][key] = {
		file: key + '.png'
	}
}
for (let i = 1; i <= 40; i++) {
	let key = 'xxy0' + (i >= 10 ? i : '0' + i)
	emojiList['xxy'][key] = {
		file: key + '.png'
	}
}
for (let i = 1; i <= 20; i++) {
	let key = 'lt0' + (i >= 10 ? i : '0' + i)
	emojiList['lt'][key] = {
		file: key + '.png'
	}
}

// 内容
for (let emoji in emojiList) {
	let emojiItem = emojiList[emoji]
	for (let key in emojiItem) {
		let item = emojiItem[key]
		item.img = `${emojiBaseUrl}/${emoji}/${item.file}`
	}
	// 封面
	albumArr.push({
		album: emoji,
		img: emojiItem[Object.keys(emojiItem)[0]]['img']
	})
}
// 添加删除按钮
emojiList['emoji']['[删除]'] = {}
emojiList['emoji']['[删除]']['img'] = iconEmojiDelete

export default {
	emojiList,
	albumArr
}
