import emojimap from './emojimap'
let emoji = emojimap.emojiList.emoji;

export const formatTime = (time) => {
  const timestr = new Date((parseInt(time) * 1000));
  const year = timestr.getFullYear().toString();
  let month = (timestr.getMonth() + 1).toString();
  if (month.length < 2) {
    month = '0' + month
  }
  let date = timestr.getDate().toString();
  if (date.length < 2) {
    date = '0' + date
  }
  let hour = timestr.getHours().toString();
  if (hour.length < 2) {
    hour = '0' + hour
  }
  let minute = timestr.getMinutes().toString();
  if (minute.length < 2) {
    minute = '0' + minute
  }
  let second = timestr.getSeconds().toString();
  if (second.length < 2) {
    second = '0' + second
  }
  let datetime = year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
  return datetime
};

export const calcTimeHeader = (time) => {
  // 格式化传入时间
  let date = new Date(parseInt(time)),
    year = date.getUTCFullYear(),
    month = date.getUTCMonth(),
    day = date.getDate(),
    hour = date.getHours(),
    minute = date.getUTCMinutes()
  // 获取当前时间
  let currentDate = new Date(),
    currentYear = date.getUTCFullYear(),
    currentMonth = date.getUTCMonth(),
    currentDay = currentDate.getDate();
  // 计算是否是同一天
  if (currentYear == year && currentMonth == month && currentDay == day) {//同一天直接返回
    if (hour > 12) {
      return `下午 ${hour}:${minute < 10 ? '0' + minute : minute}`
    } else {
      return `上午 ${hour}:${minute < 10 ? '0' + minute : minute}`
    }
  }
  // 计算是否是昨天
  let yesterday = new Date(currentDate - 24 * 3600 * 1000)
  if (year == yesterday.getUTCFullYear() && month == yesterday.getUTCMonth && day == yesterday.getDate()) {//昨天
    return `昨天 ${hour}:${minute < 10 ? '0' + minute : minute}`
  } else {
    return `${year}-${month + 1}-${day} ${hour}:${minute < 10 ? '0' + minute : minute}`
  }
};
/**
 * 生成富文本节点
 */
export const generateRichTextNode = (text) => {
  let tempStr = text;
  let richTextNode = [];
  let leftBracketIndex = tempStr.indexOf('[');
  let rightBracketIndex = tempStr.indexOf(']');
  let countOfWord = 0;
  Array.from(tempStr).map(item => {
    if (item != '[' && item != ']') {
      countOfWord++
    }
  });
  if (leftBracketIndex == -1 || rightBracketIndex == -1 || countOfWord == 0) {//没有emoji
    richTextNode.push({
      type: 'text',
      text: tempStr
    });
    return richTextNode
  }
  while (tempStr.length != 0) {
    leftBracketIndex = tempStr.indexOf('[');
    rightBracketIndex = tempStr.indexOf(']');
    if (leftBracketIndex == 0) { // 开头是[
      rightBracketIndex = tempStr.indexOf(']');
      if (rightBracketIndex == -1) {
        richTextNode.push({
          type: 'text',
          text: tempStr
        });
        tempStr = ''
      } else {
        let emojiName = tempStr.slice(0, rightBracketIndex + 1);
        if (emoji[emojiName]) { // 有效emoji
          richTextNode.push({
            name: 'img',
            attrs: {
              width: '20rpx',
              height: '20rpx',
              src: emoji[emojiName].img
            }
          })
        } else {//无效emoji
          richTextNode.push({
            type: 'text',
            text: emojiName
          })
        }
        tempStr = tempStr.substring(rightBracketIndex + 1, tempStr.length)
      }
    } else { // 开头不是[
      if (leftBracketIndex == -1) {// 最后全是文字
        richTextNode.push({
          type: 'text',
          text: tempStr.slice(0, tempStr.length)
        });
        tempStr = ''
      } else {
        richTextNode.push({
          type: 'text',
          text: tempStr.slice(0, leftBracketIndex)
        });
        tempStr = tempStr.substring(leftBracketIndex, tempStr.length + 1)
      }
    }
  }
  return richTextNode
};
/**
 * 处理图片富文本节点
 */
export const generateImageNode = (file) => {
  // console.log(file)
  let width = 0, height = 0;
  if (file.w > 150) {
    width = 150;
    height = file.h / (file.w / 150)
  } else {
    width = file.w;
    height = file.h
  }
  let richTextNode = [];
  richTextNode.push({
    name: 'img',
    attrs: {
      width: `${width}rpx`,
      height: `${height}rpx`,
      src: file.url
    }
  });
  return richTextNode
};