<template>
	<div>
		<div class="navbar">
			<div class="title">{{title}}</div>
			<div class="left-icon" @click="back">
				<div class="iconfont icon-fanhui"></div>
			</div>
		</div>
		<div class="zhanwei" v-if="!immersive"></div>
	</div>
</template>

<script>
	export default{
		props:{
			title:{
				type:String,
				default:""
			},
			immersive:{
				type:Boolean,
				default:false
			}
		},
		data(){
			return{
				
			}
		},
		methods:{
			back(){
				this.$router.back(-1);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.navbar{
		position: fixed;
		top: 0;
		height: 90px;
		width: 100%;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		background-color: #fff;
		z-index: 99;
		// box-shadow: 0 0 15px 1px rgba(0,0,0,0.05);
		border-bottom: 1px solid #f5f5f5;
		.title{
			font-size: 30px;
		}
		.left-icon{
			position: absolute;
			left: 0;
			width: 80px;
			height: 90px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.icon-fanhui{
				font-size: 30px;
			}
		}
	}
	.zhanwei{
		height: 90px;
	}
</style>
